import { MouseEvent } from 'react';

import { CursorSupportT } from '@global/types';
import { dispatcher, store } from '@redux/redux';

type SettingsT = {
    targetClassName: string;
    parentClassName?: string;
    className?: string;
    observerSupportClassName?: string;
};

const deleteCursorSupport = function ({ id }: { id: CursorSupportT['_id'] }): void {
    const cursorSupport = store.getState().cursorSupport.map((item) => ({ ...item }));

    const index = cursorSupport.findIndex((item) => item._id === id);

    if (index !== -1) {
        cursorSupport.splice(index, 1);

        dispatcher({ type: 'cursorSupport', data: cursorSupport });

        const observerIndex = observers.findIndex((item) => item.id === id);

        if (observerIndex !== -1) {
            observers[observerIndex].observer.disconnect();

            window.removeEventListener(
                'scroll',
                observers[observerIndex].documentScrollHandler,
                true,
            );

            observers.splice(observerIndex, 1);
        }
    }
};

const getSizeItem = function ({ content, settings }: { content: string; settings: SettingsT }): {
    width: number;
    height: number;
} {
    const div = document.createElement('div');
    const divInner = document.createElement('div');

    div.classList.add('v2cursorSupport__item');

    if (settings.className) {
        div.classList.add(settings.className);
    }

    divInner.classList.add('v2cursorSupport__itemInner');

    divInner.innerHTML = content;

    div.style.position = 'absolute';
    div.style.zIndex = '-1';
    div.style.top = '0';
    div.style.left = '0';
    div.style.opacity = '0';
    div.style.pointerEvents = 'none';

    div.appendChild(divInner);

    document.body.appendChild(div);

    const width = div.offsetWidth;
    const height = div.offsetHeight;

    div.remove();

    return { width, height };
};

const getPosition = function ({
    target,
    support,
    settings,
}: {
    target: HTMLElement;
    support: CursorSupportT;
    settings: SettingsT;
}): { left: number; top: number; dir: CursorSupportT['dir'] } {
    const targetLeft = target.getBoundingClientRect().x;
    const targetTop = target.getBoundingClientRect().y;
    const { width, height } = getSizeItem({ content: support.content, settings });
    const margin = 10;
    let leftDelta = 0;
    let topDelta = 0;
    let dir = support.dir;

    if (support.dir === 'top') {
        leftDelta = 0;
        topDelta = -target.offsetHeight / 2 - height / 2 - margin;
    }

    if (support.dir === 'bottom') {
        leftDelta = 0;
        topDelta = target.offsetHeight / 2 + height / 2 + margin;
    }

    let resultLeft = targetLeft + target.offsetWidth / 2 - width / 2 + leftDelta;
    let resultTop = targetTop + target.offsetHeight / 2 - height / 2 + topDelta;

    if (resultTop + height - 20 > document.documentElement.clientHeight) {
        const reversePosition = getPosition({
            target,
            support: { ...support, dir: 'top' },
            settings,
        });

        dir = 'top';

        resultLeft = reversePosition.left;
        resultTop = reversePosition.top;
    }

    return { left: resultLeft, top: resultTop, dir };
};

const observers: {
    id: string;
    observer: MutationObserver;
    documentScrollHandler: (e: Event) => void;
}[] = [];

const addCursorSupport = function ({
    e,
    support,
    settings,
}: {
    e: MouseEvent;
    support: CursorSupportT;
    settings: SettingsT;
}): void {
    const cursorSupport = store.getState().cursorSupport.map((item) => ({ ...item }));
    let target = (e.target as HTMLElement).closest(settings.targetClassName) as HTMLElement;

    if (settings.parentClassName) {
        target = (e.target as HTMLElement)
            .closest(settings.parentClassName)
            ?.querySelector(settings.targetClassName) as HTMLElement;
    }

    if (target) {
        const { left, top, dir } = getPosition({ target, support, settings });

        const otherSupport = cursorSupport.find((item) => item._id === support._id);

        if (!otherSupport) {
            cursorSupport.push({
                ...support,
                position: { left, top },
                dir,
                className: settings.className,
            });
            const observer = new MutationObserver((ev: MutationRecord[]) => {
                if (ev[0].removedNodes) {
                    const node = ev[0].removedNodes[0];

                    if (node && (node === target || node.contains(target))) {
                        observer.disconnect();

                        deleteCursorSupport({ id: support._id });
                    }
                }
            });

            const parent = target.closest(
                settings.observerSupportClassName || '.body__content',
            ) as HTMLElement;

            if (parent) {
                observer.observe(parent, { childList: true, subtree: true });

                const documentScrollHandler = () => {
                    deleteCursorSupport({ id: support._id });
                };

                observers.push({ id: support._id, observer, documentScrollHandler });

                window.addEventListener('scroll', documentScrollHandler, true);
            }

            dispatcher({ type: 'cursorSupport', data: cursorSupport });
        }
    }
};

const setCursorSupport = function (
    support: Omit<CursorSupportT, 'className'>,
    settings: SettingsT,
): {
    onMouseEnter: (e: MouseEvent) => void;
    onMouseLeave: (e: MouseEvent) => void;
} {
    if (store.getState().device === 'mobile') {
        return {} as any;
    }

    const props = {
        onMouseEnter: (e: MouseEvent) => {
            addCursorSupport({
                e,
                support,
                settings,
            });
        },
        onMouseLeave: () => {
            deleteCursorSupport({
                id: support._id,
            });
        },
    };

    return props;
};

export { addCursorSupport, deleteCursorSupport, setCursorSupport };
