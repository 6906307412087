import getUserName from '@functions/getUserName.ts';

import I from '../types.ts';

const getCreaterName: I['getCreaterName'] = function () {
    const { user, message } = this.props;
    const { infoCreater } = message;

    let createrName = '';

    if (infoCreater) {
        if (infoCreater.nameOfRole) {
            createrName += infoCreater.nameOfRole;
            createrName += ' – ';
        }

        createrName += getUserName({
            type: 'double',
            user: infoCreater,
        });
    }

    if (!createrName && process.env.REACT_APP_SYSTEM !== 'app') {
        createrName = 'Удаленный аккаунт';
    }

    if (user._id === message.createrId) {
        createrName = 'Вы';
    }

    return createrName;
};

export default getCreaterName;
