import React from 'react';

import Items from '@components/items/Items.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';

import addMessages from './methods/addMessages.ts';
import checkDisabled from './methods/checkDisabled.ts';
import deleteMessage from './methods/deleteMessage.ts';
import editMessage from './methods/editMessage.ts';
import endVoice from './methods/endVoice.ts';
import getArea from './methods/getArea.ts';
import getChat from './methods/getChat.ts';
import getItems from './methods/getItems.ts';
import getMessagesKeys from './methods/getMessagesKeys.ts';
import getNodeId from './methods/getNodeId.ts';
import initChat from './methods/initChat.ts';
import initVoice from './methods/initVoice.ts';
import keysHandler from './methods/keysHandler.ts';
import proccessVoice from './methods/proccessVoice.ts';
import readMessages from './methods/readMessages.ts';
import scrollHandler from './methods/scrollHandler.ts';
import scrollToNotReadMessage from './methods/scrollToNotReadMessage.ts';
import scrollToStart from './methods/scrollToStart.ts';
import sendMessage from './methods/sendMessage.ts';
import sendVoice from './methods/sendVoice.ts';
import setContentHeight from './methods/setContentHeight.ts';
import setEvents from './methods/setEvents.ts';
import setMessageContent from './methods/setMessageContent.ts';
import setMessages from './methods/setMessages.ts';
import setMessagesSize from './methods/setMessagesSize.ts';
import showTemplatesPopup from './methods/showTemplatesPopup.ts';
import socketHandler from './methods/socketHandler.ts';
import startVoice from './methods/startVoice.ts';
import uploadFiles from './methods/uploadFiles.ts';
import visibilityChange from './methods/visibilityChange.ts';
import voiceTouchOutHandler from './methods/voiceTouchOutHandler.ts';

import ChatWindowI from './types.ts';

import renderContent from './renders/renderContent.tsx';
import renderFoot from './renders/renderFoot.tsx';
import renderFootActions from './renders/renderFootActions.tsx';
import renderFootArea from './renders/renderFootArea.tsx';
import renderFootFiles from './renders/renderFootFiles.tsx';
import renderFootFilesAction from './renders/renderFootFilesAction.tsx';
import renderFootSendAction from './renders/renderFootSendAction.tsx';
import renderFootTemplatesAction from './renders/renderFootTemplatesAction.tsx';
import renderHead from './renders/renderHead.tsx';
import renderMessage from './renders/renderMessage.tsx';
import renderTags from './renders/renderTags.tsx';

class ChatWindow extends Items<ChatWindowI['props'], ChatWindowI['state']> implements ChatWindowI {
    parent: ChatWindowI['parent'];

    constructor(props: ChatWindowI['props']) {
        super(props);
        this.state = {
            fileModel: {
                files: [],
            },
        };

        this.keysHandler = this.keysHandler.bind(this);
        this.visibilityChange = this.visibilityChange.bind(this);
        this.voiceTouchOutHandler = this.voiceTouchOutHandler.bind(this);
        this.socketHandler = this.socketHandler.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 50;
    voice = [];
    messagesSizes = {};
    processReadMessages = {};

    initChat = initChat;
    getChat = getChat;
    getItems = getItems;
    getMessagesKeys = getMessagesKeys;
    getNodeId = getNodeId;

    startVoice = startVoice;
    proccessVoice = proccessVoice;
    endVoice = endVoice;
    sendVoice = sendVoice;
    initVoice = initVoice;

    keysHandler = keysHandler;
    voiceTouchOutHandler = voiceTouchOutHandler;
    visibilityChange = visibilityChange;
    checkDisabled = checkDisabled;

    setContentHeight = setContentHeight;
    scrollToNotReadMessage = scrollToNotReadMessage;
    scrollHandler = scrollHandler;
    scrollToStart = scrollToStart;
    setMessagesSize = setMessagesSize;
    readMessages = readMessages;

    showTemplatesPopup = showTemplatesPopup;
    uploadFiles = uploadFiles;

    sendMessage = sendMessage;
    addMessages = addMessages;
    editMessage = editMessage;
    deleteMessage = deleteMessage;
    setMessageContent = setMessageContent;
    setMessages = setMessages;

    getArea = getArea;

    setEvents = setEvents;
    socketHandler = socketHandler;

    renderHead = renderHead;
    renderTags = renderTags;
    renderContent = renderContent;
    renderMessage = renderMessage;
    renderFoot = renderFoot;
    renderFootFiles = renderFootFiles;
    renderFootArea = renderFootArea;
    renderFootActions = renderFootActions;
    renderFootTemplatesAction = renderFootTemplatesAction;
    renderFootSendAction = renderFootSendAction;
    renderFootFilesAction = renderFootFilesAction;

    componentDidMount() {
        this.initChat();
    }

    componentWillUnmount(): void {
        this.setEvents(true);
    }

    render() {
        const { chat } = this.state;
        const { mode } = this.props;
        const disabled = this.checkDisabled();
        const classNames = [
            `_${process.env.REACT_APP_SYSTEM}`,
            ...(chat ? ['_ready'] : []),
            ...(mode ? [`_mode-${mode}`] : []),
            ...(disabled ? ['_disabled'] : []),
        ];

        return (
            <div
                ref={this.parent}
                className={`chatWindow _COL _${chat?._id} ${classNames.join(' ')}`}
            >
                <LoaderBlock
                    className="chatWindow__loader"
                    loaderClassName="_main"
                    isShow={!chat}
                />
                <div className="chatWindow__inner">
                    {chat && (
                        <>
                            {this.renderHead()}
                            {this.renderTags()}
                            {this.renderContent()}
                            {this.renderFoot()}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default ChatWindow;
