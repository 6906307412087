import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import ChatWindow from '../../crm/chat/components/window/Window';

class Inner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { levels, user } = this.props;

        return <ChatWindow user={user} id={levels[1]} />;
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(Inner);

Inner.propTypes = {
    user: PropTypes.object,
    levels: PropTypes.array,
};
