import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import { setCursorActions } from '@functions/actionsHandler.ts';

import I from '../types.ts';

const renderMoreBtn: I['renderMoreBtn'] = function (actions) {
    if (!actions.length) {
        return <></>;
    }

    const { message } = this.props;
    const moreProps = setCursorActions(
        { _id: `chatMessage-more-${message._id}`, dir: 'left', items: actions },
        { targetClassName: '.chatMessage__more' },
    );

    return (
        <div className="chatMessage__more _CLICK" {...moreProps}>
            <i className="chatMessage__moreIcon">
                <Icon name="more-vertical" />
            </i>
        </div>
    );
};

export default renderMoreBtn;
