import getRealParams, { RealParamsItemT } from '@functions/getRealParams.ts';

import I from '../types.ts';

const setContentHeight: I['setContentHeight'] = function () {
    const parentNode = this.parent.current;

    if (!parentNode) {
        return;
    }

    const contentNode = parentNode.querySelector('.chatWindow__content') as HTMLElement | null;

    if (!contentNode) {
        return;
    }

    const headNode = parentNode.querySelector('.chatWindow__head') as HTMLElement | null;
    const tagsNode = parentNode.querySelector('.chatWindow__tags') as HTMLElement | null;
    const footNode = parentNode.querySelector('.chatWindow__foot') as HTMLElement | null;

    const elems: { id: string; className: string }[] = [];

    if (headNode) {
        elems.push({ id: 'head', className: '.chatWindow__head' });
    }

    if (tagsNode) {
        elems.push({ id: 'tags', className: '.chatWindow__tags' });
    }

    if (footNode) {
        elems.push({ id: 'foot', className: '.chatWindow__foot' });
    }

    const params = getRealParams({
        parent: parentNode,
        classNames: ['_static'],
        elems,
        width: parentNode.offsetWidth,
        isClearStyleParent: true,
        // isNotRemove: true,
    });

    let contentHeight = parentNode.offsetHeight;

    elems.forEach(({ id }) => {
        const elemParams = params[id] as RealParamsItemT | undefined;

        if (elemParams) {
            contentHeight -= elemParams.height;
        }
    });

    contentNode.style.height = `${contentHeight}px`;
};

export default setContentHeight;
