import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const editMessage: I['editMessage'] = async function (id) {
    const { items } = this.state;
    const message = items!.find((innerMessage) => innerMessage._id === id);

    if (!message || !message.content) {
        return;
    }

    const checkNode = document.createElement('div');

    checkNode.innerHTML = message.content;

    await setAsyncState.call(this, {
        editedMessageId: id,
        message: checkNode.innerText,
        updatedAreaKey: new Date().getTime(),
    });

    setTimeout(() => {
        const area = this.getArea();

        area.selectionStart = area.value.length;
        area.selectionEnd = area.value.length;

        area.focus();
        // this.scrollToMessage({ id });
    }, 10);
};

export default editMessage;
