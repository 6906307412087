import React from 'react';

import I from '../types.ts';

const renderText: I['renderText'] = function () {
    const { message } = this.props;
    const { resultContent, type } = message;
    const createrName = this.getCreaterName();
    const [file] = message.files;

    if (file) {
        return <></>;
    }

    return (
        <>
            {createrName && (
                <div className="chatMessage__creater">
                    <div className="chatMessage__createrInner">{createrName}</div>
                </div>
            )}
            <div
                className="chatMessage__text"
                dangerouslySetInnerHTML={{ __html: resultContent! }}
            ></div>
            {type === 'comments' && (
                <>
                    <div className="chatMessage__commentsSupport">Внутренний комментарий</div>
                </>
            )}
        </>
    );
};

export default renderText;
