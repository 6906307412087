import I from '../types.ts';

const checkDisabled: I['checkDisabled'] = function () {
    const { chat, user } = this.state;

    if (!chat) {
        return false;
    }

    if (chat.entity.id === user?._id) {
        return false;
    }

    return chat.responsible !== user?._id;
};

export default checkDisabled;
