import React from 'react';

import List from '@components/list/List.tsx';
import ListScroll from '@components/listScroll/ListScroll.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const {
        items = [],
        isReady,
        isLimit,
        isDisabledScroll,
        isShowMoreLoader,
        messagesRenderKey,
        messagesUpdateKey,
    } = this.state;

    return (
        <div className={`chatWindow__content ${isReady ? '_ready' : ''} _${this.getNodeId()}`}>
            <LoaderBlock
                loaderClassName="_main"
                className="chatWindow__contentLoader"
                isShow={!isReady}
            />
            <LoaderBlock
                loaderClassName="_main"
                className="chatWindow__contentLoader _white"
                isShow={!!isShowMoreLoader}
                isScroll={true}
                isReverse={true}
            />

            <div
                className="chatWindow__contentScroll _NOSCROLL"
                onScroll={this.scrollHandler.bind(this)}
            >
                {isReady && (
                    <ListScroll
                        isInit={!!isReady}
                        getParent={() =>
                            this.parent.current?.querySelector(
                                '.chatWindow__contentScroll',
                            ) as HTMLElement
                        }
                        callback={this.getMoreItems}
                        startCounter={this.stepCounter}
                        stepCounter={this.stepCounter}
                        maxCounter={Infinity}
                        lengthCurrent={items.length}
                        handlerLoaderList={this.handlerLoaderList}
                        isLimit={!!isLimit}
                        isDisabledScroll={!!isDisabledScroll}
                        isReverse={true}
                    >
                        <List
                            renderKey={messagesRenderKey}
                            updateKey={messagesUpdateKey}
                            items={items}
                            parentClass="chatWindow__contentMessages"
                            itemClass={`chatWindow__contentMessagesItem _${this.getNodeId()}`}
                            itemStyleProps={[]}
                            parentStyleProps={[]}
                            relative={true}
                            renderItem={this.renderMessage.bind(this)}
                            reverse={true}
                            callback={({ isInit }) => {
                                if (isInit) {
                                    this.scrollToNotReadMessage();
                                }

                                setTimeout(() => {
                                    this.setMessagesSize();

                                    if (isInit) {
                                        this.scrollHandler();
                                    }
                                }, 300);
                            }}
                        />
                    </ListScroll>
                )}
            </div>
        </div>
    );
};

export default renderContent;
