import React from 'react';

import Link from '@components/link/Link.tsx';

import ManualTableI, { TableBlockItemT } from '../types.ts';

import { TableT } from '../static/tables.ts';

const renderRow: ManualTableI['renderRow'] = function ({ item, prop: id, key, fake }) {
    const { activeBlockId } = this.state;
    const {
        name,
        isNotLink,
        isBlock,
        link,
        getRowClassName,
        currents,
        currentsHandler,
        currentsLabel,
        isList,
        currentsMulti,
        linkCallback,
        rowOnClick,
        withChoice = true,
    } = this.props;
    const className = '';
    const blockItem = item as TableBlockItemT;
    const table = this.tables[name] as TableT;

    let Row = isNotLink ? 'div' : Link;

    let propsLink: Record<any, unknown> = {};

    if (isNotLink) {
        propsLink = {};
    } else {
        propsLink = link
            ? {
                  pageName: link.pageName,
                  ids: { [link.level || 0]: id },
                  callback: linkCallback,
              }
            : {
                  pageName: `manual-${name}-inner`,
                  ids: { 3: id },
                  callback: linkCallback,
              };
    }

    if (currentsLabel && currentsHandler) {
        Row = 'label';
    }

    if (isBlock && blockItem.corporations.length > 1) {
        propsLink.onClick = () => {
            this.setState(
                { activeBlockId: this.state.activeBlockId === id ? undefined : id },
                this.rowBlockSizeHandler,
            );
        };
    }

    if (typeof rowOnClick === 'function') {
        propsLink.onClick = () => {
            rowOnClick(item);
        };
    }

    const orderCols =
        this.getOrderCols() as unknown as (typeof this.tables)[keyof typeof this.tables]['colsOrder'];

    let propsRowClassName = '';

    if (getRowClassName) {
        propsRowClassName = getRowClassName(item);
    }

    const isActive = !!currents?.find((innerItem) => innerItem.id === item._id);

    if (currentsHandler && isActive && withChoice) {
        propsRowClassName += ' _active';
    }

    if (table.rowClassName) {
        propsRowClassName += ` ${table.rowClassName}`;
    }

    return (
        <Row
            className={`v2table__row _static ${key % 2 === 0 ? '_odd' : ''} ${isBlock ? '_block' : ''} ${
                propsLink.onClick ? '_CLICK' : ''
            } ${activeBlockId === id ? '_blockShow' : ''} ${className} ${propsRowClassName} ${Row === 'label' ? '_CLICKFORCE' : ''} ${fake ? '_fake' : ''}`}
            {...propsLink}
            data-id={id}
        >
            <div className="v2table__rowInner _row">
                {isBlock ? (
                    <>
                        {blockItem.corporations.map((corporation, corporationIndex) => (
                            <div
                                className={`v2table__rowBlock _row ${
                                    corporationIndex === 0 ? '_main' : ''
                                } ${['executorMvds'].includes(name) ? '_visible' : ''} ${
                                    blockItem.corporations.length === 1 ? '_one' : ''
                                }`}
                                key={corporation._id}
                            >
                                {orderCols.map((colName, colIndex) =>
                                    this.renderCol({
                                        name: colName,
                                        item,
                                        corporation,
                                        corporationIndex,
                                        fake,
                                        index: colIndex,
                                    }),
                                )}
                            </div>
                        ))}
                    </>
                ) : (
                    <>
                        {orderCols.map((colName, colIndex) =>
                            this.renderCol({ name: colName, item, fake, index: colIndex }),
                        )}
                    </>
                )}
                {isList && withChoice && (
                    <div
                        className={`v2table__rowChoice _COL _CLICK ${isActive ? '_active' : ''} ${fake ? '_LOADERWAIT' : ''}`}
                        onClick={() => {
                            if (currentsHandler) {
                                currentsHandler(id, item, !currentsMulti);
                            }
                        }}
                    ></div>
                )}
            </div>
        </Row>
    );
};

export default renderRow;
