import React from 'react';

import { RenderColsT } from '../../types.ts';

const renderDefaultList: RenderColsT<any> = function ({ name: colName, item }) {
    let content;
    let className;

    const resultItem = item as { _id: string; name: string };

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{resultItem.name}</div>
            </>
        );
    }

    return { content, className };
};

export default renderDefaultList;
