import getFormatedNumber from '@functions/getFormatedNumber.ts';

import I from '../types.ts';

const getCurrentDuration: I['getCurrentDuration'] = function () {
    const { currentTime } = this.state;
    const { file } = this.props;

    if (file.duration) {
        const durationInt = +(currentTime !== undefined ? currentTime : file.duration).toFixed(0);
        const minutes = Math.floor(durationInt / 60);
        const seconds = durationInt - minutes * 60;

        return {
            minutes: minutes.toString(),
            seconds: getFormatedNumber(seconds),
        };
    }
};

export default getCurrentDuration;
