import React from 'react';

import checkChange from './methods/checkChange.ts';
import startTimer from './methods/startTimer.ts';

import VoiceBarI from './types.ts';

class VoiceBar
    extends React.Component<VoiceBarI['props'], VoiceBarI['state']>
    implements VoiceBarI
{
    parent: VoiceBarI['parent'];
    intervalId: VoiceBarI['intervalId'];

    constructor(props: VoiceBarI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    startTimer = startTimer;
    checkChange = checkChange;

    componentDidMount() {
        this.checkChange();
    }

    componentDidUpdate() {
        this.checkChange();
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    render() {
        return (
            <div ref={this.parent} className="chatVoiceBar _row">
                <div className="chatVoiceBar__timer"></div>
            </div>
        );
    }
}

export default VoiceBar;
