import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import getUpdateFormData from '@functions/getUpdateFormData.js';
import handlerLoading from '@functions/handlerLoading.ts';
import setAsyncState from '@functions/setAsyncState.ts';
import { ReqResponseT } from '@global/types.ts';

import I, { WindowChatMessageT } from '../types.ts';

const sendMessage: I['sendMessage'] = async function ({ message, isVoice, formData }) {
    const { id } = this.props;
    const { fileModel, editedMessageId, template, loadingKey } = this.state;
    const resultMessage = message || this.state.message;

    if (loadingKey || (!resultMessage && !fileModel.files.length && !isVoice)) {
        return;
    }

    const body: ObjT = {
        id,
    };

    if (formData) {
        formData.set('id', id);
    }

    if (!isVoice && resultMessage) {
        body.content = resultMessage;
    }

    if (editedMessageId) {
        body.editedMessageId = editedMessageId;
    }

    if (template) {
        body.templateId = template._id;
    }

    if (fileModel.files.length) {
        body.files = fileModel.files;
    }

    await handlerLoading.call(this, 'send');

    let response;

    try {
        response = await axios.put<{}, { data: ReqResponseT<{ messages: WindowChatMessageT[] }> }>(
            `${process.env.REACT_APP_API}/chat`,
            formData ? getUpdateFormData(formData) : body,
            { headers: getHeaders() },
        );

        const { success, data } = response.data;

        if (success) {
            const { messages } = data;

            this.voice = [];

            if (editedMessageId) {
                const resultMessages = [...this.state.items!.map((item) => structuredClone(item))];
                const editedMessage = resultMessages.find((item) => item._id === editedMessageId);

                if (editedMessage) {
                    editedMessage.content = this.state.message;
                    editedMessage.resultContent = this.setMessageContent({
                        message: editedMessage,
                    });
                }

                await setAsyncState.call(this, {
                    items: resultMessages,
                    updatedAreaKey: new Date().getTime(),
                    message: '',
                    editedMessageId: undefined,
                    ...this.getMessagesKeys({ messages: resultMessages }),
                });

                setTimeout(() => {
                    this.getArea().focus();
                }, 10);
            } else if (messages) {
                await this.addMessages({
                    messages: messages.reverse(),
                    message: '',
                    fileModel: {
                        files: [],
                    },
                });

                setTimeout(() => {
                    this.scrollToStart();
                    this.getArea().focus();
                }, 10);
            }
        }
    } catch (error) {}

    await handlerLoading.call(this, undefined);
};

export default sendMessage;
