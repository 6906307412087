import lists from '../lists';

const listArticles = {
    ...lists,
    query: [{ key: 'type', value: 'articles' }],
    emptyInfo: {
        title: 'Статей пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default listArticles;
