import I from '../types.ts';

const setMessages: I['setMessages'] = function ({ messages }) {
    const { user } = this.props;
    const chat = this.state.chat!;

    return messages.map((message) => {
        let isMe = message.createrId === user._id;
        let notRead = false;
        let isRead = false;

        if (process.env.REACT_APP_SYSTEM === 'crm') {
            if (chat.type !== 'support' || chat.entity.id !== user._id) {
                isMe = message.createrId !== chat.entity.id;
            }
        }

        if (!message.type) {
            if (!isMe) {
                notRead =
                    chat.entity.id === user._id
                        ? !message.usersWhoRead.includes(user._id)
                        : !message.isReadFromResponsible;
            } else {
                isRead =
                    chat.entity.id !== user._id
                        ? message.usersWhoRead.includes(chat.entity.id!)
                        : message.isReadFromResponsible;
            }
        }

        message.isMe = isMe;
        message.notRead = notRead;
        message.isRead = isRead;

        return {
            ...message,
            resultContent: this.setMessageContent({ message }),
        };
    });
};

export default setMessages;
