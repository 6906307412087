import I from '../types.ts';

const setMessagesSize: I['setMessagesSize'] = function () {
    const messages = this.state.items!.filter((item) => item.notRead);
    const contentScrollNode = this.parent.current!.querySelector(
        '.chatWindow__contentScroll',
    ) as HTMLElement;
    const contentScrollTop = contentScrollNode.getBoundingClientRect().y;
    const contentScrollVal = contentScrollNode.scrollTop;

    messages.forEach((message) => {
        const messageNode = this.parent.current!.querySelector(
            `.chatWindow__contentMessage[data-id="${message._id}"]`,
        ) as HTMLElement;

        if (messageNode) {
            this.messagesSizes[message._id] = {
                top: messageNode.getBoundingClientRect().y - contentScrollTop + contentScrollVal,
                height: messageNode.offsetHeight,
            };
        }
    });
};

export default setMessagesSize;
