import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import LazyImage from '@components/lazyImage/lazyImage.tsx';
import { setCursorActions } from '@functions/actionsHandler.ts';
import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const renderFileImage: I['renderFileImage'] = function () {
    const { message } = this.props;
    const [file] = message.files;

    const isHorizontal = file && file.width! / file.height! > 1.5;
    const horizontalHeight = isHorizontal
        ? +((this.maxWidth / file.width!) * file.height!).toFixed(0)
        : null;

    const moreActions = this.getImageMoreActions({ id: file._id });
    const moreProps = setCursorActions(
        { _id: `chatMessageImage-more-${message._id}`, dir: 'left', items: moreActions },
        { targetClassName: '.chatMessage__imageActions' },
    );

    return (
        <div className="chatMessage__images">
            <div
                className="chatMessage__image _CLICK"
                key={file._id}
                style={isHorizontal ? { height: `${horizontalHeight}px` } : {}}
            >
                {process.env.REACT_APP_SYSTEM === 'crm' && (
                    <>
                        <div className="chatMessage__imageActions _COL" {...moreProps}>
                            <i className="chatMessage__imageActionsIcon">
                                <Icon name="more-vertical" />
                            </i>
                        </div>
                    </>
                )}
                <div
                    className="chatMessage__imagePreview _COL"
                    onClick={() => {
                        if (process.env.REACT_APP_SYSTEM === 'crm') {
                            handlerPopup('galeryPopup', {
                                isShow: true,
                                files: [
                                    {
                                        type: file.type,
                                        src: file.path,
                                        fullSrc: file.fullSrc,
                                        id: file._id,
                                    },
                                ],
                                title: `Чат`,
                            });
                        }
                    }}
                >
                    <i className="chatMessage__imagePreviewIcon">
                        <Icon name="search" />
                    </i>
                </div>
                <LazyImage src={file.fullSrc!} className="chatMessage__imageItem" />
            </div>
        </div>
    );
};

export default renderFileImage;
