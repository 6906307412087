import React from 'react';

import DealStepT from '@global/models/DealStep.ts';

import { RenderColsT } from '../../types.ts';

const renderListDealSteps: RenderColsT<'listDealSteps'> = function ({ name: colName, item }) {
    let content;
    let className;

    const dealStep = item as Pick<DealStepT, '_id' | 'color' | 'name'>;

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__step">
                    <div className="v2table__stepColor" style={{ background: dealStep.color }} />
                    {dealStep.name}
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderListDealSteps;
