import I from '../types.ts';

const voiceTouchOutHandler: I['voiceTouchOutHandler'] = function (e) {
    if (!this.state.isVoiceProccess) {
        return;
    }

    const voiceAction = this.parent.current!.querySelector(
        '.chatWindow__footAction._voice',
    ) as HTMLElement;
    const x = e.changedTouches[0].pageX;
    const y = e.changedTouches[0].pageY;

    const actionX = voiceAction.getBoundingClientRect().x;
    const actionY = voiceAction.getBoundingClientRect().y;

    if (
        !(
            x > actionX &&
            x < actionX + voiceAction.offsetWidth &&
            y > actionY &&
            y < actionY + voiceAction.offsetHeight
        )
    ) {
        this.endVoice(true);
    }
};

export default voiceTouchOutHandler;
