import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import setAsyncState from '@functions/setAsyncState.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const deleteMessage: I['deleteMessage'] = async function (this: I, { id }) {
    const chat = this.state.chat!;

    try {
        const response = await axios.patch<
            {},
            { data: ReqResponseT<{ deletedMessages: string[] }> }
        >(
            `${process.env.REACT_APP_API}/chat`,
            { id: chat._id, action: 'delete-message', messagesIds: [id] },
            { headers: getHeaders() },
        );

        if (response.data.success) {
            const { deletedMessages } = response.data.data;

            await this.deleteItems({ ids: deletedMessages });
            await setAsyncState.call(this, {
                ...this.getMessagesKeys({ messages: this.state.items! }),
            });
        }
    } catch (error) {}
};

export default deleteMessage;
