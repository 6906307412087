import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import checkValueOfEmpty from '../functions/checkValueOfEmpty';

class InputRunning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.checkBreak = this.checkBreak.bind(this);

        this.input = React.createRef();
    }

    scrollValue = -10;

    intervalId = null;

    run() {
        let breakScroll = this.input.current.scrollWidth - this.input.current.offsetWidth;
        let dir = 1;

        if (breakScroll > 2) {
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }

            this.intervalId = setInterval(() => {
                if (this.input.current) {
                    breakScroll = this.input.current.scrollWidth - this.input.current.offsetWidth;

                    if (this.scrollValue > breakScroll + 20) {
                        dir = -1;
                    }
                    if (this.scrollValue <= -20) {
                        dir = 1;
                    }

                    this.scrollValue += 0.5 * dir;

                    this.input.current.scrollLeft = this.scrollValue;
                }
            }, 15);
        }
    }

    checkBreak() {
        const { isRun } = this.props;

        if (isRun && this.input.current) {
            const breakScroll = this.input.current.scrollWidth - this.input.current.offsetWidth;

            if (breakScroll !== this.breakScroll && breakScroll !== 0) {
                this.breakScroll = breakScroll;

                this.run();
            }
        }
    }

    checkStart() {
        const { isRun } = this.props;

        if (isRun === true) {
            if (isRun !== this.isRun) {
                this.isRun = isRun;

                this.run();
            }
            if (this.props.value !== this.value) {
                this.value = this.props.value;

                this.run();
            }
        } else {
            this.isRun = isRun;

            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
        }
    }

    startX = 0;

    moveX = 0;

    componentDidMount() {
        if (checkValueOfEmpty(this.props.isRun)) {
            this.checkStart();

            setInterval(this.checkBreak, 500);

            this.input.current.addEventListener(
                'touchstart',
                (e) => {
                    this.startX = e.changedTouches[0].pageX;
                },
                { passive: false },
            );
            this.input.current.addEventListener(
                'touchmove',
                (e) => {
                    this.moveX = e.changedTouches[0].pageX - this.startX;

                    if (Math.abs(this.moveX) > 2) {
                        e.preventDefault();
                    }
                },
                { passive: false },
            );
            this.input.current.addEventListener(
                'mousewheel',
                (e) => {
                    if (Math.abs(e.deltaX) > 1) {
                        e.preventDefault();
                    }
                },
                { passive: false },
            );
        }
    }

    componentDidUpdate() {
        this.checkStart();
    }

    render() {
        const props = { ...this.props };

        delete props.isRun;
        delete props.dispatch;

        return <input ref={this.input} {...props} />;
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(InputRunning);

InputRunning.propTypes = {
    children: PropTypes.node,
    isRun: PropTypes.bool,
    value: PropTypes.string,
};
