import I from '../types.ts';

const startVoice: I['startVoice'] = function () {
    if (!this.state.message && !this.state.isVoiceProccess && navigator.mediaDevices) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(
            (stream) => {
                if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }

                this.stream = stream;
                this.mediaRecorder = new MediaRecorder(stream);

                this.mediaRecorder.ondataavailable = this.proccessVoice.bind(this);
                this.mediaRecorder.onstop = this.sendVoice.bind(this);

                this.mediaRecorder.start(500);

                this.setState({ isVoiceProccess: true });
            },
            () => null,
        );
    }
};

export default startVoice;
