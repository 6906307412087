const dealGroups = {
    colsOrder: ['current', 'name', 'dealsCount'],
    cols: {
        current: {
            content: 'Текущая',
            width: 12,
        },
        name: {
            content: 'Название',
            width: 68,
        },
        dealsCount: {
            content: 'Кол-во сделок',
            width: 20,
        },
    },
    rowClassName: '_colCenter',
    deletesCols: [],
    url: 'dealGroup',
    emptyInfo: {
        title: 'Воронок пока нет',
        description: 'Вы можете создать сделку по кнопке справа',
    },
} as const;

export default dealGroups;
