import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import { s } from '@functions/seo';

import Animate from './Animate.jsx';
// import Icon from './icon/Icon.tsx';
import Loader from './Loader.jsx';

class ImageLazy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
        };

        this.load = this.load.bind(this);
    }

    load() {
        const { load } = this.props;

        // setTimeout(() => {
        this.setState(
            (state) => {
                const newState = { ...state };

                newState.isLoad = true;

                return newState;
            },
            () => {
                if (load && typeof load === 'function') {
                    load();
                }
            },
        );
        // }, 2000);
    }

    render() {
        let { isLoad } = this.state;
        const {
            className = ``,
            src,
            position,
            isWait,
            setStateLoader,
            stateLoader,
            style = {},
        } = this.props;

        if (stateLoader === -1) {
            isLoad = true;
        }

        return (
            <div
                className={`imageLazy ${className} _parent ${isLoad ? '_load' : ''}`}
                style={style}
            >
                <Animate
                    className="imageLazy__empty"
                    isShow={!isLoad}
                    setState={setStateLoader}
                    state={stateLoader}
                >
                    <Loader className="_main" />
                </Animate>

                <Animate
                    className="imageLazy__item _col"
                    isShow={!isWait}
                    isSmoothShow={true}
                    name="imageLazy"
                >
                    {!s() && (
                        <img
                            className={`imageLazy__itemInner ${className}`}
                            src={src}
                            alt=""
                            style={position ? { objectPosition: position } : {}}
                            onLoad={this.load}
                        />
                    )}
                </Animate>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ImageLazy);

ImageLazy.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    load: PropTypes.func,
    position: PropTypes.string,
    isWait: PropTypes.bool,
    setStateLoader: PropTypes.func,
    stateLoader: PropTypes.bool,
    style: PropTypes.object,
};
