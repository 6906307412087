import React from 'react';

import I from '../types.ts';

const renderFootActions: I['renderFootActions'] = function () {
    return (
        <div className="chatWindow__footActions _ROW">
            {this.renderFootTemplatesAction()}
            {this.renderFootSendAction()}
            {this.renderFootFilesAction()}
        </div>
    );
};

export default renderFootActions;
