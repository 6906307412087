import I from '../types.ts';

const checkChange: I['checkChange'] = function (force) {
    const { renderKey, updateKey, forceRenderKey, disabled } = this.props;

    if (disabled) {
        return;
    }

    if (
        renderKey !== this.renderKey ||
        updateKey !== this.updateKey ||
        forceRenderKey !== this.forceRenderKey ||
        force
    ) {
        this.updateItems({
            isRender: renderKey !== this.renderKey || forceRenderKey !== this.forceRenderKey,
            isUpdate: updateKey !== this.updateKey || forceRenderKey !== this.forceRenderKey,
        });

        this.renderKey = renderKey;
        this.updateKey = updateKey;
        this.forceRenderKey = forceRenderKey;
    }
};

export default checkChange;
