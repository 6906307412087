const deals = {
    colsOrder: ['name', 'executor', 'step', 'tags', 'responsible', 'tasks'],
    cols: {
        name: {
            content: 'Название',
            width: 17,
        },
        executor: {
            content: 'Исполнитель',
            width: 20,
        },
        step: {
            content: 'Этап сделки',
            width: 16,
        },
        tags: {
            content: 'Теги',
            width: 18,
        },
        responsible: {
            content: 'Ответственный',
            width: 19,
        },
        tasks: {
            content: 'Задачи',
            width: 10,
        },
    },
    deletesCols: [],
    url: 'deal',
    emptyInfo: {
        title: 'Сделок пока нет',
        description: 'Вы можете создать сделку по кнопке справа',
    },
} as const;

export default deals;
