import React from 'react';

import CorporationInfo from '@components/crm/manual/CorporationInfo.jsx';
import checkImage from '@functions/checkImage.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';

import getCreaterName from './methods/getCreaterName.ts';
import getImageMoreActions from './methods/getImageMoreActions.ts';
import getMoreActions from './methods/getMoreActions.ts';

import ChatMessageI from './types.ts';

import renderAvatar from './renders/renderAvatar.tsx';
import renderFile from './renders/renderFile.tsx';
import renderFileDoc from './renders/renderFileDoc.tsx';
import renderFileImage from './renders/renderFileImage.tsx';
import renderMoreBtn from './renders/renderMoreBtn.tsx';
import renderReadState from './renders/renderReadState.tsx';
import renderText from './renders/renderText.tsx';

class ChatMessage
    extends React.Component<ChatMessageI['props'], ChatMessageI['state']>
    implements ChatMessageI
{
    parent: ChatMessageI['parent'];

    constructor(props: ChatMessageI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    maxWidth = process.env.REACT_APP_SYSTEM === 'app' ? 292 : 400;

    getCreaterName = getCreaterName;
    getMoreActions = getMoreActions;
    getImageMoreActions = getImageMoreActions;

    renderFile = renderFile;
    renderFileImage = renderFileImage;
    renderFileDoc = renderFileDoc;
    renderText = renderText;
    renderReadState = renderReadState;
    renderAvatar = renderAvatar;
    renderMoreBtn = renderMoreBtn;

    render() {
        const { message } = this.props;
        const { isMe, type, corporation } = message;
        const [file] = message.files;
        const actions = this.getMoreActions();
        const withCorporation = corporation && !isMe;
        const classNames = [
            `_${process.env.REACT_APP_SYSTEM}`,
            ...(file ? ['_file'] : []),
            ...(isMe ? ['_me'] : []),
            ...(type ? [`_${type}`] : []),
            ...(actions.length ? ['_withMore'] : []),
            ...(withCorporation ? ['_withCorporation'] : []),
            ...(file && checkImage(file.fullSrc!) ? ['_image'] : []),
        ];

        return (
            <div ref={this.parent} className={`chatMessage _COL ${classNames.join(' ')}`}>
                <div className="chatMessage__wrapper _COL">
                    <div className="chatMessage__wrapperBox _COL">
                        <div className="chatMessage__inner">
                            {this.renderFile()}
                            {this.renderText()}
                            {this.renderReadState()}
                        </div>
                        {this.renderAvatar()}
                    </div>
                    <div className="chatMessage__time">
                        {getFormatedDate({ date: new Date(message.createdDate), type: 'time' })}
                    </div>
                </div>
                {withCorporation && (
                    <div className="chatMessage__corporation">
                        <CorporationInfo id={message._id} {...corporation} short={true} />
                    </div>
                )}

                {this.renderMoreBtn(actions)}
            </div>
        );
    }
}

export default ChatMessage;
