import React from 'react';

import actionHandler from './methods/actionHandler.ts';
import getActions from './methods/getActions.ts';
import getLink from './methods/getLink.ts';
import getUserName from './methods/getUserName.ts';

import WindowHeadI from './types.ts';

import renderAction from './renders/renderAction.tsx';
import renderActions from './renders/renderActions.tsx';
import renderInfo from './renders/renderInfo.tsx';

class WindowHead
    extends React.Component<WindowHeadI['props'], WindowHeadI['state']>
    implements WindowHeadI
{
    parent: WindowHeadI['parent'];

    constructor(props: WindowHeadI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getUserName = getUserName;
    getLink = getLink;
    getActions = getActions;

    actionHandler = actionHandler;

    renderActions = renderActions;
    renderAction = renderAction;
    renderInfo = renderInfo;

    render() {
        return (
            <div ref={this.parent} className="chatWindowHead _ROW">
                {this.renderInfo()}
                {this.renderActions()}
            </div>
        );
    }
}

export default WindowHead;
