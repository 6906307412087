import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import List from '@components/list/List.tsx';
import { ChatMessageT } from '@global/models/ChatMessage.ts';

import File from '../components/file/File.tsx';

import I from '../types.ts';

const renderFootFiles: I['renderFootFiles'] = function () {
    const { fileModel } = this.state;
    const renderKey = fileModel.files.map((file) => file._id).join('');
    const updateKey = fileModel.files.map((file) => file.fullSrc).join('');

    return (
        <List
            renderKey={renderKey}
            updateKey={updateKey}
            items={fileModel.files}
            parentClass="chatWindow__files"
            itemClass="chatWindow__filesItem"
            itemStyleProps={['top', 'left']}
            parentStyleProps={['width']}
            renderItem={({ item: file }: { item: ChatMessageT['files'][number] }) => (
                <div className="chatWindow__file">
                    <div
                        className="chatWindow__fileDelete _CLICK _COL"
                        onClick={async () => {
                            await this.deleteArrayItem([`files.${file._id}`], undefined, 'fileModel');
                        }}
                    >
                        <i className="chatWindow__fileDeleteIcon">
                            <Icon name="delete" />
                        </i>
                    </div>
                    <File className="_default" file={file} />
                </div>
            )}
            callback={this.setContentHeight.bind(this)}
            // testMode={true}
        />
    );
};

export default renderFootFiles;
