import I from '../types.ts';

const initAudio: I['initAudio'] = function () {
    if (this.audio.current) {
        this.audio.current.addEventListener('pause', () => {
            this.playHandler(false);
        });
        this.audio.current.addEventListener('ended', () => {
            this.setProgressLine({ percent: 1 });

            setTimeout(() => {
                this.clearAudio();
            }, 10);
        });
    }
};

export default initAudio;
