import I from '../types.ts';

const scrollHandler: I['scrollHandler'] = function () {
    const contentScrollNode = this.parent.current!.querySelector(
        '.chatWindow__contentScroll',
    ) as HTMLElement;
    const contentScrollHeight = contentScrollNode.offsetHeight;
    const contentScrollVal = -contentScrollNode.scrollTop;
    const messages = this.state.items!.filter(
        (item) => item.notRead && !this.processReadMessages[item._id],
    );

    messages.forEach((message) => {
        if (this.messagesSizes[message._id]) {
            const { top: messageTop, height: messageHeight } = this.messagesSizes[message._id];

            const resultTop = messageTop + messageHeight + contentScrollVal;

            if (resultTop > 0 && resultTop < contentScrollHeight) {
                this.processReadMessages[message._id] = message._id;
            }
        }
    });

    if (Object.keys(this.processReadMessages)) {
        if (this.readThrottle) {
            this.readThrottle();
        }
    }
};

export default scrollHandler;
