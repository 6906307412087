import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const initChat: I['initChat'] = async function () {
    const { user } = this.props;

    await setAsyncState.call(this, { user });
    await this.getChat();

    this.getArea().focus();

    this.setContentHeight();
};

export default initChat;
