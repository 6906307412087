import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import setAsyncState from '@functions/setAsyncState.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const readMessages: I['readMessages'] = async function () {
    if (Object.keys(this.processReadMessages).length === 0) {
        return;
    }

    const readMessagesIds = [...Object.keys(this.processReadMessages)];

    const chat = this.state.chat!;

    const body = {
        action: 'set-read',
        id: chat._id,
        readMessages: readMessagesIds,
    };

    try {
        const response = await axios.patch<{}, { data: ReqResponseT }>(
            `${process.env.REACT_APP_API}/chat`,
            body,
            {
                headers: getHeaders(),
            },
        );

        if (response.data.success) {
            Object.keys(this.processReadMessages).forEach((key) => {
                if (readMessagesIds.includes(key)) {
                    delete this.processReadMessages[key];
                }
            });

            const resultMessages = [...this.state.items!.map((item) => structuredClone(item))];

            readMessagesIds.forEach((id) => {
                const index = resultMessages.findIndex((item) => item._id === id);

                if (index !== -1) {
                    resultMessages[index].notRead = false;
                }
            });

            await setAsyncState.call(this, {
                items: resultMessages,
                ...this.getMessagesKeys({ messages: resultMessages }),
            });
        }
    } catch (error) {}
};

export default readMessages;
