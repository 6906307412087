import setAnimate from '@functions/setAnimate.ts';

import I from '../types.ts';

const scrollToStart: I['scrollToStart'] = function (force) {
    const scrollNode = this.parent.current!.querySelector(
        '.chatWindow__contentScroll',
    ) as HTMLElement;

    if (force || -scrollNode.scrollTop > 2000) {
        scrollNode.scrollTop = 0;
    } else {
        const startScrollVal = scrollNode.scrollTop;

        setAnimate({
            draw: (progress) => {
                scrollNode.scrollTo({
                    top: startScrollVal + progress * -startScrollVal,
                });
            },
            duration: 300,
        });
    }
};

export default scrollToStart;
