import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const socketHandler: I['socketHandler'] = async function ({ detail }) {
    const { chat, user } = this.state;
    const { name, data } = detail;

    // console.log(detail);

    if (!chat || name !== 'chat' || data?.id !== chat._id) {
        return;
    }

    const { fields, type, initiatorUserId } = data;

    if (fields && type === 'newMessages') {
        const { messages, ...otherFields } = fields;

        if (messages && initiatorUserId !== user?._id) {
            await this.addMessages({
                messages,
            });
        }

        await this.change({ ...otherFields }, 'chat');
    }

    if (['update', 'setRead'].includes(type)) {
        await this.change({ ...fields }, 'chat');

        if (this.state.chat?.responsible === user?._id) {
            this.scrollHandler();
        }
    }

    if (type === 'deleteMessages') {
        const { deletedMessages, ...otherFields } = fields;

        await this.change({ ...otherFields }, 'chat');

        if (deletedMessages && initiatorUserId !== user?._id) {
            await this.deleteItems({ ids: deletedMessages });
            await setAsyncState.call(this, {
                ...this.getMessagesKeys({ messages: this.state.items! }),
            });
        }
    }
};

export default socketHandler;
