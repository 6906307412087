import React from 'react';

import CountryT from '@global/models/Country.ts';

import { RenderColsT } from '../../types.ts';

const renderCountries: RenderColsT<'countries'> = function ({ name: colName, item }) {
    let content;
    let className;
    const country = item as CountryT;

    if (colName === 'name') {
        content = <>{country.name}</>;
    }

    if (colName === 'code') {
        content = <>{country.code || '–'}</>;
    }

    if (colName === 'type') {
        content = <>{country.typeName}</>;
    }

    return { content, className };
};

export default renderCountries;
