import I from '../types.ts';

const clearAudio: I['clearAudio'] = function () {
    clearInterval(this.intervalId);
    // this.handlerPlay(false);
    this.setProgressLine({ percent: 0, force: true });
    // this.audio.current.currentTime = 0;
    this.setState({ currentTime: undefined, isProccessAudio: false });
};

export default clearAudio;
