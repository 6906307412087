import removeTransition from '@functions/removeTransition.ts';
import getListItems from '@requests/getListItems.ts';

import I, { WindowChatMessageT } from '../types.ts';

const getItems: I['getItems'] = async function (this: I) {
    const { id } = this.props;
    const query = [
        ...this.getQueryForRequest().params,
        { key: 'type', value: 'messages' },
        { key: 'id', value: id },
    ];

    const { items, isLimit, counter } = await getListItems<WindowChatMessageT>({
        url: 'chat',
        query,
    });

    removeTransition({ item: `.chatWindow__contentMessagesItem._${this.getNodeId()}` });

    const messages = this.setMessages({ messages: items });

    await this.setItems({
        items: messages,
        isLimit,
        counter,
        ...this.getMessagesKeys({ messages }),
    });

    return { items };
};

export default getItems;
