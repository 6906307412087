import { s } from './seo';

export default function checkIos() {
    if (process.env.REACT_APP_ENV === 'local' || s()) {
        return false;
    }

    const userAgent = window.navigator.userAgent.toLowerCase();

    return /iphone|ipad|ipod/.test(userAgent);
}
