import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import Icon from '@components/icon/Icon.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';

import I from '../types.ts';

const renderFootSendAction: I['renderFootSendAction'] = function () {
    const { message, fileModel, loadingKey, isVoiceProccess } = this.state;
    const voiceProps =
        process.env.REACT_APP_SYSTEM === 'app'
            ? {
                  onTouchStart: () => {
                      this.startVoice();
                  },
                  onTouchEnd: () => {
                      this.endVoice();
                  },
              }
            : {
                  onMouseDown: () => this.startVoice(),
                  onMouseLeave: () => this.endVoice(true),
                  onMouseUp: () => this.endVoice(),
              };

    return (
        <div
            className={`chatWindow__footAction _COL _voice ${isVoiceProccess ? '_active' : ''}`}
            {...voiceProps}
        >
            <Animate
                className="chatWindow__footAction _COL _send"
                onClick={(e) => {
                    e.stopPropagation();

                    this.sendMessage({});
                }}
                isShow={!!message || fileModel.files.length > 0}
            >
                <div className="chatWindow__footActionInner _COL _CLICK">
                    <LoaderBlock
                        className="chatWindow__footActionLoader"
                        isShow={loadingKey === 'send'}
                    />
                    <i className="chatWindow__footActionIcon">
                        <Icon name="chat-send" />
                    </i>
                </div>
            </Animate>
            {(process.env.REACT_APP_SYSTEM === 'app' ? [1, 2, 3, 4] : [1, 2, 3]).map((item) => (
                <div className={`chatWindow__footActionBack _${item}`} key={item}></div>
            ))}
            <div className="chatWindow__footActionInner _COL _CLICK">
                <LoaderBlock
                    className="chatWindow__footActionLoader"
                    isShow={loadingKey === 'send'}
                />
                <i className="chatWindow__footActionIcon">
                    <Icon name="chat-voice" />
                </i>
            </div>
        </div>
    );
};

export default renderFootSendAction;
