import I from '../types.ts';

const setProgress: I['setProgress'] = async function (e) {
    if (process.env.REACT_APP_SYSTEM === 'app' || !this.state.isProccessAudio) {
        return;
    }

    // await setAsyncState.call(this, { isProccessAudio: true });
    // await setAsyncTimer(10);

    const progressLine = this.parent.current!.querySelector(
        '.chatFile__audioProgressLine',
    ) as HTMLElement;
    const { offsetWidth: widthLine } = progressLine;
    const leftLine = progressLine.getBoundingClientRect().x;
    const percent = (e.pageX - leftLine) / widthLine;
    const { duration } = this.audio.current!;

    this.audio.current!.currentTime = duration * percent;

    this.setProgressLine({ percent, force: true });

    const currentTimeInt = Math.floor(duration * percent);

    this.setState({ currentTime: currentTimeInt });
};

export default setProgress;
