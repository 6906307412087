import uploadFile from '@requests/uploadFile.ts';

import I from '../types.ts';

const uploadFiles: I['uploadFiles'] = async function (e) {
    const { fileModel } = this.state;
    const targetFiles = e.target.files!;
    const currentMax = 6;

    if (fileModel.files.length >= currentMax) {
        return;
    }

    const ids = (await this.addArrayItem!(
        {
            key: 'files',
            item: Object.keys(targetFiles)
                .filter((item, index) => index < currentMax)
                .map(() => ({})),
        },
        'fileModel',
    )) as string[];

    await Promise.all(
        (Object.keys(targetFiles) as (keyof typeof targetFiles)[]).map(async (key, index) => {
            if (index < currentMax) {
                const id = ids[index];
                const file = targetFiles[key];

                const formData = new FormData();

                formData.set('file', file as File);

                const { fullSrc, src, size, name } = await uploadFile({ formData });

                this.change(
                    {
                        [`files.${id}.fullSrc`]: fullSrc,
                        [`files.${id}.src`]: src,
                        [`files.${id}.size`]: size,
                        [`files.${id}.name`]: name,
                    },
                    'fileModel',
                );
            }
        }),
    );
};

export default uploadFiles;
