import React from 'react';

import getDateText from '@functions/getDateText.ts';

import Message from '../components/message/Message.tsx';

import I from '../types.ts';

const renderMessage: I['renderMessage'] = function ({ item: message }) {
    const chat = this.state.chat!;
    const user = this.state.user!;
    const type = message.type || 'message';

    return (
        <div
            className={`chatWindow__contentMessage ${message.isMe ? '_me' : ''} _type-${type}`}
            data-notread={message.notRead ? message.notRead : undefined}
            data-id={message._id}
        >
            <div className="chatWindow__contentMessageInner">
                {['start', 'end'].includes(message.type!) && (
                    <div className="chatWindow__session _row">
                        <div className="chatWindow__sessionInner">
                            <b>Обращение</b> {message.type === 'start' ? 'начато ' : 'завершено '}
                            {getDateText(message.createdDate)}
                        </div>
                    </div>
                )}
                {(!message.type || message.type === 'comments') && (
                    <Message
                        message={message}
                        user={user}
                        chat={chat}
                        deleteMessage={this.deleteMessage.bind(this)}
                        editMessage={this.editMessage.bind(this, message._id)}
                    />
                )}
            </div>
        </div>
    );
};

export default renderMessage;
