import I from '../types.ts';

const endVoice: I['endVoice'] = function (isCancel) {
    if (this.state.isVoiceProccess) {
        this.mediaRecorder!.stop();

        if (typeof isCancel === 'boolean') {
            this.isCancelVoice = isCancel;
        }

        this.stream!.getTracks().forEach((track) => {
            track.stop();
        });
    }
};

export default endVoice;
