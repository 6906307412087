import I from '../types.ts';

const keysHandler: I['keysHandler'] = function (e) {
    if (e.type === 'keydown') {
        if (e.keyCode === 16) {
            this.pressShift = true;
        }

        if (e.keyCode === 13 && this.pressShift !== true) {
            e.preventDefault();

            this.sendMessage({});
        }
    }

    if (e.type === 'keyup') {
        if (e.keyCode === 16) {
            this.pressShift = false;
        }
    }
};

export default keysHandler;
