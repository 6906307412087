const contentBlog = {
    colsOrder: ['preview', 'title', 'section', 'system'],
    cols: {
        preview: {
            content: 'Превью',
            width: 17,
        },
        title: {
            content: 'Название',
            width: 53,
        },
        section: {
            content: 'Раздел',
            width: 15,
        },
        system: {
            content: 'Система',
            width: 15,
        },
    },
    deletesCols: [],
    url: 'article',
    emptyInfo: {
        title: 'Статей пока нет',
        description: 'Вы можете создать статью по кнопке справа',
    },
    query: [
        { key: 'type', value: 'blog' },
        { key: 'system', value: 'crm' },
        { key: 'private', value: 'true' },
    ],
} as const;

export default contentBlog;
