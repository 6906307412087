import React from 'react';

import List from '@components/list/List.tsx';

import CounterI from './types.ts';

class Counter extends React.Component<CounterI['props'], CounterI['state']> implements CounterI {
    parent: CounterI['parent'];

    constructor(props: CounterI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { counter, className = '' } = this.props;

        return (
            <List
                renderKey={counter > 0 ? counter.toString() : '0'}
                items={counter > 0 ? [{ _id: counter.toString() }] : []}
                parentClass={`v2counter _fullHeight _fullWidth ${className}`}
                itemStyleProps={[]}
                parentStyleProps={['height']}
                changeAnimate={true}
                renderItem={({ item: counterItem }) => (
                    <>
                        <div className="v2counter__item _COL">{counterItem._id}</div>
                    </>
                )}
            />
        );
    }
}

export default Counter;
