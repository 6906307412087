import I from '../types.ts';

const initVoice: I['initVoice'] = function () {
    if (navigator.mediaDevices) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(
            (stream) => {
                this.stream = stream;

                this.stream.getTracks().forEach((track) => {
                    track.stop();
                });
            },
            () => null,
        );
    }
};

export default initVoice;
