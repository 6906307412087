import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';

import I from '../types.ts';

const renderAvatar: I['renderAvatar'] = function () {
    if (process.env.REACT_APP_SYSTEM !== 'crm') {
        return <></>;
    }

    const { message } = this.props;
    const { infoCreater } = message;

    return (
        <div className="chatMessage__avatar">
            <Avatar
                className="_grey"
                image={infoCreater?.avatar?.src}
                firstSymb={infoCreater?.firstName?.[0]}
                secondSymb={infoCreater?.secondName?.[0]}
            />
        </div>
    );
};

export default renderAvatar;
