import React from 'react';

import getFormatedDate from '@functions/getFormatedDate';
import setSpacesInText from '@functions/setSpacesInText';
import LogT from '@global/models/Log.ts';

import { RenderColsT } from '../../types.ts';

const renderLogs: RenderColsT<'modelLogs'> = function ({ name: colName, item }) {
    const log = item as LogT;
    let content;

    if (colName === 'content') {
        content = (
            <>
                <div
                    className="v2table__text _wrapWord"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(log.content) }}
                />
            </>
        );
    }

    if (colName === 'user') {
        content = <>{this.renderUser({ item: { _id: log._id, user: log.creater } })}</>;
    }

    if (colName === 'date') {
        content = (
            <>
                {getFormatedDate({
                    date: new Date(log.createdDate || log.cDate),
                    type: 'full',
                    withSeconds: true,
                })}
            </>
        );
    }

    return { content };
};

export default renderLogs;
