import React from 'react';

import Tag from '@components/crm/tag/Tag.tsx';
import List from '@components/list/List.tsx';
import TagT from '@global/models/Tag.ts';

import I from '../types.ts';

const renderTags: I['renderTags'] = function () {
    const chat = this.state.chat!;
    const { mode } = this.props;

    if (process.env.REACT_APP_SYSTEM !== 'crm' || mode === 'popup') {
        return <></>;
    }

    const renderKey = chat.tags.map((tag) => tag._id).join('');

    return (
        <List
            renderKey={renderKey}
            items={chat.tagsInfo}
            parentClass="chatWindow__tags"
            itemClass="chatWindow__tagsItem"
            itemStyleProps={['left', 'top']}
            parentStyleProps={['width']}
            renderWrapper={(children) => (
                <div className="chatWindow__tagsItems _ROW">{children}</div>
            )}
            renderItem={({ item }: { item: TagT }) => (
                <div className="chatWindow__tag">
                    <Tag color={item.colorOfGroup!} isFull={true}>
                        {item.name}
                    </Tag>
                </div>
            )}
            callback={this.setContentHeight.bind(this)}
        />
    );
};

export default renderTags;
