import ItemsI, { ItemT } from '../types.ts';

const addItem: ItemsI['addItem'] = function (this: ItemsI, { item, inStart }) {
    if (this.state.items?.find((innerItem) => innerItem._id === item._id)) {
        return new Promise((resolve) => {
            this.updateItem({ id: item._id, fields: item }).then(resolve);
        });
    }

    return new Promise((resolve) => {
        this.setState((state) => {
            const newState = { ...state } as ItemsI['state'];
            const items: ItemT[] = JSON.parse(JSON.stringify(newState.items || []));

            if (!items.find((innerItem) => innerItem._id === item._id)) {
                items[inStart ? 'unshift' : 'push'](item);
            }

            newState.items = items;
            newState.updatedItemKey = new Date().getTime();

            return newState;
        }, resolve);
    });
};

export default addItem;
