import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const playHandler: I['playHandler'] = async function (this: I, isPlay = !this.state.isPlay) {
    await setAsyncState.call(this, { isPlay, isProccessAudio: true });

    const audioNode = this.audio.current!;

    if (isPlay) {
        this.initAudio();
        audioNode.play();

        if (this.state.currentTime === null) {
            this.setState({ currentTime: 0 });
        }

        this.intervalId = setInterval(() => {
            const { currentTime, duration } = audioNode;
            const currentTimeInt = Math.floor(currentTime);

            if (currentTimeInt > this.state.currentTime!) {
                this.setState({ currentTime: currentTimeInt });
            }

            if (currentTime <= duration) {
                this.setProgressLine({ percent: currentTime / duration });
            }
        }, 10);

        document.dispatchEvent(
            new CustomEvent('audio-play', {
                detail: { ref: this.audio.current },
            }),
        );
    } else {
        clearInterval(this.intervalId);
        audioNode.pause();
    }
};

export default playHandler;
