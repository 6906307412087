import lists from '../lists';

const listUsers = {
    ...lists,
    query: [{ key: 'type', value: 'users' }],
    rowClassName: '_choiceTop',
    emptyInfo: {
        title: 'Пользователей пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default listUsers;
