import React from 'react';

import Icon from '@components/icon/Icon.tsx';

import I from '../types.ts';

const renderFootFilesAction: I['renderFootFilesAction'] = function () {
    const { editedMessageId } = this.state;
    const disabled = this.checkDisabled();

    return (
        <label className={`chatWindow__footAction _COL ${disabled ? '_disabled' : ''}`}>
            <div className="chatWindow__footActionInner _COL _CLICK">
                <input
                    type="file"
                    className="chatWindow__footActionInput"
                    style={{ display: 'none' }}
                    multiple
                    accept="image/*,application/*"
                    onChange={this.uploadFiles.bind(this)}
                    disabled={disabled || !!editedMessageId}
                />
                <i className="chatWindow__footActionIcon">
                    <Icon name="chat-attach" />
                </i>
            </div>
        </label>
    );
};

export default renderFootFilesAction;
