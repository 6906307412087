import lists from '../lists';

const listArticleKnowledgeSections = {
    ...lists,
    query: [{ key: 'type', value: 'articleKnowledgeSections' }],
    emptyInfo: {
        title: 'Разделов пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default listArticleKnowledgeSections;
