import React from 'react';

import PopupWrapper from '@components/popupWrapper/PopupWrapper.tsx';

import RootI from '../types.ts';

const renderPopups: RootI['renderPopups'] = function () {
    const { filterPopup, alertPopup, calendarPopup } = this.props;

    return (
        <>
            <PopupWrapper name="filter" isShow={!!filterPopup?.isShow} className="_filter" />
            <PopupWrapper
                name="alert"
                isShow={!!alertPopup?.isShow}
                className="_alert"
                props={{ alertPopup }}
            />
            <PopupWrapper name="calendar" isShow={!!calendarPopup?.isShow} className="_calendar" />
        </>
    );
};

export default renderPopups;
