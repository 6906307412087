import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';
import LazyImage from '@components/lazyImage/lazyImage.tsx';

import I from '../types.ts';

const renderFile: I['renderFile'] = function () {
    const { support, onChange, prop, fileProp, fileAccept, fileAbsolute } = this.props;
    const value = this.props.value as string;

    return (
        <>
            <div className="v2widgetField__field _file">
                <label
                    className={`v2widgetField__file _ROW ${value ? '_upload' : ''} ${fileAbsolute ? '_abs' : ''}`}
                >
                    <input
                        type="file"
                        className="v2widgetField__fileInput"
                        onChange={this.fileHandler.bind(this)}
                        accept={
                            fileAccept ? fileAccept.map((item) => `.${item}`).join(',') : undefined
                        }
                    />
                    <div className="v2widgetField__filePreview _COL">
                        <Animate
                            className="v2widgetField__filePreviewInfo _COL"
                            isShow={!!value}
                            onClick={(e) => {
                                if (value) {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    onChange({
                                        [prop]: null,
                                        ...(fileProp ? { [fileProp]: null } : {}),
                                    });
                                }
                            }}
                        >
                            <div className="v2widgetField__filePreviewInfoDelete">
                                <Icon name="actions-delete" />
                            </div>
                            {fileAbsolute && (
                                <>
                                    <div className="v2widgetField__fileSupport">{support}</div>
                                    <div className="v2widgetField__fileInfo">Загружен</div>
                                </>
                            )}
                        </Animate>
                        <div className="v2widgetField__filePreviewInner _COL">
                            <i className="v2widgetField__filePreviewIcon">
                                <Icon name="add" />
                            </i>
                            {fileAbsolute && (
                                <>
                                    <div className="v2widgetField__fileSupport">{support}</div>
                                    <div className="v2widgetField__fileInfo">Не загружен</div>
                                </>
                            )}
                        </div>

                        <AnimateChange
                            className="v2widgetField__filePreviewImage _default"
                            renderKey={value}
                            styles={[]}
                            withoutParams={true}
                        >
                            <LazyImage
                                className="v2widgetField__filePreviewImageInner"
                                src={value!}
                                checkFit={true}
                            />
                        </AnimateChange>
                    </div>
                    {!fileAbsolute && (
                        <div className="v2widgetField__fileContent">
                            <div className="v2widgetField__fileSupport">{support}</div>
                            <AnimateChange className="v2widgetField__fileInfo" renderKey={!!value}>
                                {value ? <>Загружен</> : <>Не загружен</>}
                            </AnimateChange>
                        </div>
                    )}
                </label>
            </div>
        </>
    );
};

export default renderFile;
