import getFormatedDate from './getFormatedDate';

export default function getDateText(date: Date | string): string {
    const resultDate = new Date(date);
    const notTime = new Date().getTime();
    const dateTime = resultDate.getTime();
    const dateDiff = notTime - dateTime;

    if (dateDiff < 60_000) {
        return 'Сейчас';
    }

    if (dateDiff < 60_000 * 60) {
        return `${Math.floor(dateDiff / 60_000)} мин. назад`;
    }

    if (dateDiff < 60_000 * 60 * 24) {
        return `${Math.floor(dateDiff / (60_000 * 60))} ч. назад`;
    }

    if (dateDiff < 60_000 * 60 * 24 * 30) {
        return `${Math.floor(dateDiff / (60_000 * 60 * 24))} д. назад`;
    }

    return getFormatedDate({ date: resultDate, type: 'full', isShortYear: true });
}
