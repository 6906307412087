import { CursorActionsItemT } from '@global/types.ts';

import I from '../types.ts';

const getMoreActions: I['getMoreActions'] = function () {
    const { user, chat, message, deleteMessage, editMessage } = this.props;
    const { isMe } = message;
    const actions: CursorActionsItemT[] = [];
    const dateDiff =
        (new Date().getTime() - new Date(message.createdDate).getTime()) / (1_000 * 60 * 60);
    const condForMore =
        process.env.REACT_APP_SYSTEM === 'crm' &&
        isMe &&
        message.createrId === user._id &&
        dateDiff <= 48 &&
        (message.type === 'comments' || chat.type !== 'telegram' || !!message.anyInfo?.messageId);

    if (condForMore) {
        actions.push({
            name: 'delete',
            key: 'delete',
            onClick: async () => {
                await deleteMessage({ id: message._id });
            },
        });

        if (
            (chat.responsible === user._id || chat.entity.id === user._id) &&
            typeof message.content === 'string'
        ) {
            actions.push({
                name: 'edit',
                key: 'edit',
                onClick: editMessage,
            });
        }
    }

    return actions;
};

export default getMoreActions;
