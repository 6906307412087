import React from 'react';

import Tag from '@components/crm/tag/Tag.tsx';
import getDateText from '@functions/getDateText.ts';
import getEndText from '@functions/getEndText.ts';
import getUserName from '@functions/getUserName.ts';
import DealT from '@global/models/Deal.ts';

import { RenderColsT } from '../../types.ts';

const renderDeals: RenderColsT<'deals'> = function ({ name: colName, item }) {
    let content;
    let className;
    const deal = item as DealT;

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__colorLine" style={{ background: deal.stepColor }} />
                <div className="v2table__text _block _bottom">
                    <b>Сделка №{deal.number}</b>
                </div>
                <div className="v2table__text _grey">{getDateText(deal.createdDate)}</div>
            </>
        );
    }

    if (colName === 'executor') {
        content = (
            <>
                <div className="v2table__text _block">
                    {getUserName({
                        user: {
                            firstName: deal.eFirstName,
                            secondName: deal.eSecondName,
                            thirdName: deal.eThirdName,
                        },
                    })}
                </div>
                <div className="v2table__text _grey">{deal.categoryName || '–'}</div>
            </>
        );
    }

    if (colName === 'step') {
        content = (
            <>
                <div className="v2table__step">
                    <div className="v2table__stepColor" style={{ background: deal.stepColor }} />
                    {deal.stepName}
                </div>
            </>
        );
    }

    if (colName === 'tags') {
        content = (
            <>
                {deal.tags.length ? (
                    <div className="v2table__tags">
                        {deal.tags.map((tag) => (
                            <div className="v2table__tag" key={tag._id}>
                                <Tag color={tag.groupColor} isFull={true}>
                                    {tag.name}
                                </Tag>
                            </div>
                        ))}
                    </div>
                ) : (
                    <>–</>
                )}
            </>
        );
    }

    if (colName === 'responsible') {
        content = deal.responsibleUser ? (
            <>
                {this.renderUser({
                    item: {
                        _id: deal._id,
                        user: { ...deal.responsibleUser },
                    },
                    isShort: true,
                })}
            </>
        ) : (
            <>–</>
        );
    }

    if (colName === 'tasks') {
        content = (
            <div className={`v2table__text ${!deal.taskCounter ? '_grey' : ''}`}>
                {deal.taskCounter} {getEndText(deal.taskCounter!, ['задача', 'задачи', 'задач'])}
            </div>
        );
    }

    return { content, className };
};

export default renderDeals;
