import I from '../types.ts';

const getSizeText: I['getSizeText'] = function () {
    const { file } = this.props;
    const convertInfo: ObjT = {};

    if (file.size! < 0.9 * 1024 * 1024) {
        convertInfo.convertSize = +(file.size! / 1024).toFixed(2);
        convertInfo.support = `Кб`;

        return `${+(file.size! / 1024).toFixed(2)}Кб`;
    }

    return `${+(file.size! / (1024 * 1024)).toFixed(2)}Мб`;
};

export default getSizeText;
