import handlerPopup from '@functions/handlerPopup.ts';
import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const showTemplatesPopup: I['showTemplatesPopup'] = function () {
    const callback = async ({ template }: { template: NonNullable<I['state']['template']> }) => {
        await setAsyncState.call(this, {
            template,
            message: template.content,
            updatedAreaKey: new Date().getTime(),
        });

        setTimeout(() => {
            this.getArea().focus();
        }, 10);
    };

    handlerPopup('chatTemplatesPopup', { isShow: true, callback });
};

export default showTemplatesPopup;
