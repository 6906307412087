import { KeyboardEvent, TouchEvent } from 'react';

import { CustomListenerT, ListenerT } from '@global/types.ts';

import I from '../types.ts';

const setEvents: I['setEvents'] = function (remove) {
    const input = this.getArea();
    const action = remove ? 'removeEventListener' : 'addEventListener';

    (input[action] as ListenerT<KeyboardEvent>)('keydown', this.keysHandler);
    (input[action] as ListenerT<KeyboardEvent>)('keyup', this.keysHandler);
    (document[action] as CustomListenerT)('getSocketData', this.socketHandler);
    document[action]('visibilitychange', this.visibilityChange);
    (document[action] as ListenerT<TouchEvent>)('touchmove', this.voiceTouchOutHandler);
};

export default setEvents;
