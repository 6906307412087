import React from 'react';

import I from '../types.ts';

import File from '../../file/File.tsx';

const renderFileDoc: I['renderFileDoc'] = function () {
    const { message } = this.props;
    const [file] = message.files;

    return (
        <div className="chatMessage__files">
            <div className="chatMessage__file" key={file._id}>
                {(() => {
                    const TagFile = file.duration ? 'div' : 'a';
                    const propsFile = file.duration
                        ? {}
                        : {
                              download: true,
                              href: file.fullSrc,
                              target: '_blank',
                              rel: 'noreferrer',
                          };

                    return (
                        <TagFile
                            className="chatSession__itemMessageFile"
                            key={file._id}
                            {...propsFile}
                        >
                            <File
                                file={file}
                                className={`_inMessage ${message.isMe ? '_me' : ''}`}
                            />
                        </TagFile>
                    );
                })()}
            </div>
        </div>
    );
};

export default renderFileDoc;
