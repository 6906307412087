import React from 'react';

import LazyImage from '@components/lazyImage/lazyImage.tsx';

import Interface from './types.ts';

class Avatar extends React.Component<Interface['props'], Interface['state']> implements Interface {
    parent: Interface['parent'];

    constructor(props: Interface['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { firstSymb, secondSymb, image, className = '', lazy = true } = this.props;
        const imageType = image ? image.split('.').pop() : null;

        return (
            <div ref={this.parent} className={`v2avatar ${className}`}>
                <div className="v2avatar__inner">
                    {image ? (
                        <>
                            {lazy ? (
                                <>
                                    <LazyImage
                                        src={image}
                                        className="v2avatar__image"
                                        cover={['jpg', 'jpeg'].includes(imageType!.toLowerCase())}
                                    />
                                </>
                            ) : (
                                <>
                                    <img src={image} alt="" className="v2avatar__image" />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="v2avatar__text _ROW">
                                {firstSymb?.toUpperCase()}
                                {secondSymb?.toUpperCase()}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default Avatar;
