import React from 'react';

import Switch from '@components/switch/Switch.tsx';
import DealGroupT from '@global/models/DealGroup.ts';

import DealGroupsI from '../../../../../views/crm/recruit/components/dealGroups/types.ts';
import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

const renderDealGroups: RenderColsT<'dealGroups'> = function ({ name: colName, item }) {
    const { loadings } = this.state;
    const dealGroup = item as DealGroupT;
    let content;
    let className;

    if (colName === 'current') {
        const { otherData } = this.props;
        const currentId = otherData?.currentId as string | undefined;
        const setCurrent = otherData?.setCurrent as DealGroupsI['setCurrent'];
        // const supportProps =
        //     joinScript.isDisabled &&
        //     setCursorSupport(
        //         {
        //             _id: `disabled-${joinScript._id}`,
        //             content: 'В данный момент<br/>проводятся подключения',
        //             dir: 'bottom',
        //         },
        //         { targetClassName: '.v2table__switch', className: '' },
        //     );

        content = (
            <>
                <div
                    className="v2table__switch"
                    // {...supportProps}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Switch
                        className="_mediumSize _green"
                        value={currentId === dealGroup._id}
                        name="isActive"
                        disabled={!!loadings[dealGroup._id]}
                        onChange={async () => {
                            await this.loadingHandler({ name: dealGroup._id, loading: true });

                            try {
                                await setCurrent({ id: dealGroup._id });
                            } catch (error) {}

                            await this.loadingHandler({ name: dealGroup._id, loading: undefined });
                        }}
                    />
                </div>
            </>
        );
    }

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{dealGroup.name}</div>
            </>
        );
    }

    if (colName === 'dealsCount') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as DealGroupsI['getMoreActions'];

        content = (
            <>
                <div className="v2table__text">{dealGroup.dealsCount}</div>
                <More
                    id={`dealGroup-${dealGroup._id}`}
                    items={getMoreActions({ dealGroup })}
                    stopPropagation={true}
                />
            </>
        );
    }

    return { content, className };
};

export default renderDealGroups;
