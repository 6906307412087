import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import Icon from '@components/icon/Icon.tsx';
import Input from '@components/input/Input.tsx';
import { setEmodziList } from '@functions/emodziListHandler.ts';
import setAsyncState from '@functions/setAsyncState.ts';

import VoiceBar from '../components/voiceBar/VoiceBar.tsx';

import I from '../types.ts';

const renderFootArea: I['renderFootArea'] = function () {
    const { chat, message = '', isVoiceProccess, loadingKey, updatedAreaKey } = this.state;

    const emodziProps = setEmodziList(
        {
            _id: `chat-emodzi-${chat?._id}`,
            dir: 'top',
            getInput: this.getArea.bind(this),
            getValue: function (this: I) {
                return this.state.message as string;
            }.bind(this),
            onChange: async (thisValue: string) => {
                await setAsyncState.call(this, {
                    message: thisValue,
                    updatedAreaKey: new Date().getTime(),
                });
            },
        },
        { targetClassName: '.chatWindow__areaEmodzi' },
    );

    return (
        <div
            className={`chatWindow__area ${message ? '_active' : ''} ${
                isVoiceProccess ? '_voices' : ''
            }`}
        >
            <Animate className="chatWindow__areaVoice" isShow={!!isVoiceProccess}>
                <VoiceBar isActive={isVoiceProccess} endVoice={this.endVoice} />
            </Animate>
            <div className="chatWindow__areaInput">
                <Input
                    className="_chat"
                    value={message}
                    name="message"
                    support="Напишите что-нибудь…"
                    onChange={async ({ value }) => {
                        await setAsyncState.call(this, { message: value as string });
                    }}
                    textarea={true}
                    calcHeight={true}
                    calcHeightCb={this.setContentHeight.bind(this)}
                    disabled={loadingKey === 'send' || this.checkDisabled()}
                    minHeight={() => 36}
                    maxHeight={() => 200}
                    updatedKey={updatedAreaKey}
                    isShowSupportInFocus={true}
                />
            </div>
            {process.env.REACT_APP_SYSTEM !== 'app' && (
                <div className="chatWindow__areaEmodzi _CLICK" {...emodziProps}>
                    <Icon name="emodzi" />
                </div>
            )}
        </div>
    );
};

export default renderFootArea;
