import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import Icon from '@components/icon/Icon.tsx';

import I from '../types.ts';

const renderAudio: I['renderAudio'] = function () {
    const { isPlay, isProccessAudio } = this.state;
    const { file } = this.props;
    const durationInfo = this.getCurrentDuration()!;
    const audioProps: ObjT = { 'webkit-playsinline': true, playsInline: true, type: 'audio/mpeg' };

    return (
        <div className="chatFile__audio _ROW">
            {isProccessAudio && (
                <audio
                    controls
                    src={file.fullSrc}
                    className="chatFile__audioInput"
                    ref={this.audio}
                    {...audioProps}
                />
            )}
            <div className="chatFile__audioButton _CLICK" onClick={() => this.playHandler()}>
                <Animate className="chatFile__audioButtonIcon _pause" isShow={!!isPlay}>
                    <Icon name="audio-pause" />
                </Animate>
                <Animate className="chatFile__audioButtonIcon _play" isShow={!isPlay}>
                    <Icon name="audio-play" />
                </Animate>
            </div>
            <div className="chatFile__audioProgress">
                <div
                    className="chatFile__audioProgressLine _CLICK"
                    onClick={this.setProgress.bind(this)}
                >
                    <div className="chatFile__audioProgressLineActive">
                        <div className="chatFile__audioProgressLineActiveDot"></div>
                    </div>
                </div>
                <div className="chatFile__audioProgressDuration">
                    {durationInfo.minutes}:{durationInfo.seconds}
                </div>
            </div>
        </div>
    );
};

export default renderAudio;
