import I from '../types.ts';

const scrollToNotReadMessage: I['scrollToNotReadMessage'] = function () {
    const notReadMessageNode = this.parent.current!.querySelector(
        '.chatWindow__contentMessage[data-notread]',
    ) as HTMLElement | null;

    if (notReadMessageNode) {
        const contentNode = this.parent.current!.querySelector(
            '.chatWindow__content',
        ) as HTMLElement;
        const contentScrollNode = this.parent.current!.querySelector(
            '.chatWindow__contentScroll',
        ) as HTMLElement;

        const offset =
            contentNode.getBoundingClientRect().y - notReadMessageNode.getBoundingClientRect().y;

        contentScrollNode.scrollTop = -offset;
    }
};

export default scrollToNotReadMessage;
