import I from '../types.ts';

const checkChange: I['checkChange'] = function () {
    const { isActive } = this.props;

    if (isActive !== this.isActive) {
        this.isActive = isActive;

        if (isActive) {
            this.startTimer();
        } else {
            clearInterval(this.intervalId);
        }
    }
};

export default checkChange;
