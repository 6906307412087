import React from 'react';

import ArticleT from '@global/models/Article.ts';

import { RenderColsT } from '../../types.ts';

const renderListArticles: RenderColsT<'listArticles'> = function ({ name: colName, item }) {
    let content;
    let className;

    const article = item as Pick<ArticleT, '_id' | 'title' | 'preview'>;

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">
                    {article.preview.fullSrc && (
                        <img src={article.preview.fullSrc} alt="" className="v2table__textIcon _article" />
                    )}
                    {article.title}
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderListArticles;
