import removeTransition from '@functions/removeTransition.ts';

import I from '../types.ts';

const setProgressLine: I['setProgressLine'] = function ({ percent, force }) {
    const { file } = this.props;
    const progressLine = this.parent.current!.querySelector(
        '.chatFile__audioProgressLineActive',
    ) as HTMLElement;
    const progressDot = this.parent.current!.querySelector(
        '.chatFile__audioProgressLineActiveDot',
    ) as HTMLElement;

    progressLine.style.width = `${percent * 100}%`;
    progressDot.style.transform = `translate(${100 - percent * 100}%, -50%)`;

    if (force) {
        removeTransition({ item: `.chatFile[data-id="${file._id}"] .chatFile__audioProgress` });
    }
};

export default setProgressLine;
