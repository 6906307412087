import lists from '../lists';

const listDealCustomFieldListItems = {
    ...lists,
    query: [{ key: 'type', value: 'dealCustomFieldListItems' }],
    emptyInfo: {
        title: 'Вариантов ответа нет',
        description: 'Необходимо добавить их в настройках воронки',
    },
} as const;

export default listDealCustomFieldListItems;
