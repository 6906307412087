import getFormatedNumber from '@functions/getFormatedNumber.ts';

import I from '../types.ts';

const startTimer: I['startTimer'] = function () {
    const { endVoice } = this.props;
    const timer = this.parent.current!.querySelector('.chatVoiceBar__timer') as HTMLElement;
    this.startDate = new Date().getTime();

    this.intervalId = setInterval(() => {
        const nowDate = new Date();

        const differentTime = nowDate.getTime() - this.startDate!;
        const mseconds = +((differentTime % 1_000) / 1000).toFixed(2) * 100;
        const seconds = Math.floor(differentTime / 1_000);
        const minutes = Math.floor(differentTime / 60_000);
        const resultTime = `${getFormatedNumber(minutes)}:${getFormatedNumber(
            seconds,
        )}:${getFormatedNumber(mseconds)}`;

        if (differentTime > 60_000 * 2) {
            endVoice();
        }

        timer.innerHTML = resultTime;
    }, 50);
};

export default startTimer;
