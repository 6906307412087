export default function getStartUrl(path: string): string {
    if (process.env.REACT_APP_SYSTEM === 'app') {
        return path;
    }

    if (
        !(
            [
                'admin',
                'auth',
                'profile',
                'support',
                'wait',
                'pep',
                'pdf',
                'clients-knowledge',
                'users-knowledge',
            ].includes(path.split('/')[0]) || path.split('/')[0]?.length === 24
        )
    ) {
        path = `/${path}`;
    }

    return path;
}
