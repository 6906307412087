import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';

import WidgetI from '../types.ts';

const renderHeadActions: WidgetI['renderHeadActions'] = function () {
    const { actions = [], inList, className, isInit } = this.props;
    const keyUpdateItem = actions.map((action) => action.updatedKey).join('');
    const keyRenderItem = actions.map((action) => action.renderKey).join('');

    return (
        <>
            <ListAbsoluteMain
                className="v2widget__headActions _ROW _ITEMS"
                items={actions}
                renderItem={this.renderHeadAction.bind(this)}
                classNameItem="v2widget__headActionsElem"
                prop="name"
                isNotNullParentSize={true}
                paramsParent={inList ? { width: true } : undefined}
                propsForUpdate={['disabled', 'filterProp', 'className', 'counter']}
                itemParams={['offsetRight', 'width']}
                styles={['width']}
                classNames={className ? className.split(' ').filter((item) => item) : []}
                keyRender={keyRenderItem}
                keyUpdateItem={keyUpdateItem}
                renderChildren={(node) => (
                    <>
                        {typeof isInit === 'boolean' && (
                            <Animate
                                className="v2widget__headActionsFake _LOADERWAIT"
                                isShow={!isInit}
                            />
                        )}

                        {node}
                    </>
                )}
                // name="test"
            />
        </>
    );
};

export default renderHeadActions;
