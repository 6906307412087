import WidgetWrapperI from '../types.ts';

const tableInit: WidgetWrapperI['tableInit'] = function (
    this: WidgetWrapperI,
    {
        updateItem,
        updateItems,
        updateManyItems,
        getFilterQuery,
        getFilter,
        updateFilter,
        searchItems,
        setFilter,
    },
) {
    this.tableUpdateItem = updateItem;
    this.tableUpdateItems = updateItems;
    this.tableUpdateManyItems = updateManyItems;
    this.getFilterQuery = getFilterQuery;
    this.getFilter = getFilter;
    this.updateFilter = updateFilter;
    this.updateFilter = updateFilter;
    this.tableSearchItems = searchItems;
    this.tableSetFilter = setFilter;
};

export default tableInit;
