import { ColsPropsT, RenderColsT } from '../../types.ts';

import renderBeelineUsers from './renderBeelineUsers.tsx';
import renderChatExecutorDocs from './renderChatExecutorDocs.tsx';
import renderChatMailings from './renderChatMailings.tsx';
import renderChatTemplates from './renderChatTemplates.tsx';
import renderCities from './renderCities.tsx';
import renderContentArticles from './renderContentArticles.tsx';
import renderCorporationBalances from './renderCorporationBalances.tsx';
import renderCorporationReports from './renderCorporationReports.tsx';
import renderCorporationRoleSection from './renderCorporationRoleSection.tsx';
import renderCorporationRoleSections from './renderCorporationRoleSections.tsx';
import renderCorporationRoles from './renderCorporationRoles.tsx';
import renderCorporations from './renderCorporations.tsx';
import renderCountries from './renderCountries.tsx';
import renderDealGroups from './renderDealGroups.tsx';
import renderDeals from './renderDeals.tsx';
import renderDefaultList from './renderDefaultList.tsx';
import renderDocs from './renderDocs.tsx';
import renderExecutorTypes from './renderExecutorTypes.tsx';
import renderExecutors from './renderExecutors.tsx';
import renderExecutorsBalances from './renderExecutorsBalances.tsx';
import renderExecutorsLimits from './renderExecutorsLimits.tsx';
import renderExecutorsMvds from './renderExecutorsMvds.tsx';
import renderExecutorsTaxes from './renderExecutorsTaxes.tsx';
import renderExportItems from './renderExportItems.tsx';
import renderIntegrations from './renderIntegrations.tsx';
import renderJoins from './renderJoins.tsx';
import renderJoinsContracts from './renderJoinsContracts.tsx';
import renderJoinsDocs from './renderJoinsDocs.tsx';
import renderJoinsScripts from './renderJoinsScripts.tsx';
import renderJoinsTemplates from './renderJoinsTemplates.tsx';
import renderListArticles from './renderListArticles.tsx';
import renderListCities from './renderListCities.tsx';
import renderListColors from './renderListColors.tsx';
import renderListCorporations from './renderListCorporations.tsx';
import renderListCountries from './renderListCountries.tsx';
import renderListCountryTypes from './renderListCountryTypes.tsx';
import renderListDealGroups from './renderListDealGroups.tsx';
import renderListDealSteps from './renderListDealSteps.tsx';
import renderListDealTriggers from './renderListDealTriggers.tsx';
import renderListDeals from './renderListDeals.tsx';
import renderListDocTypes from './renderListDocTypes.tsx';
import renderListExecutorMvdStatuses from './renderListExecutorMvdStatuses.tsx';
import renderListExecutorOrganizations from './renderListExecutorOrganizations.tsx';
import renderListExecutorTypes from './renderListExecutorTypes.tsx';
import renderListExecutors from './renderListExecutors.tsx';
import renderListIntegrationNames from './renderListIntegrationNames.tsx';
import renderListJoinScripts from './renderListJoinScripts.tsx';
import renderListJoinTemplates from './renderListJoinTemplates.tsx';
import renderListPassportViews from './renderListPassportViews.tsx';
import renderListPayProjects from './renderListPayProjects.tsx';
import renderListPays from './renderListPays.tsx';
import renderListRoles from './renderListRoles.tsx';
import renderListTags from './renderListTags.tsx';
import renderListTagsGroups from './renderListTagsGroups.tsx';
import renderListTaskKeys from './renderListTaskKeys.tsx';
import renderListTaskModels from './renderListTaskModels.tsx';
import renderListTaskTimes from './renderListTaskTimes.tsx';
import renderListUsers from './renderListUsers.tsx';
import renderLogs from './renderLogs.tsx';
import renderPayProjects from './renderPayProjects.tsx';
import renderPays from './renderPays.tsx';
import renderPaysGroups from './renderPaysGroups.tsx';
import renderStaticList from './renderStaticList.tsx';
import renderTags from './renderTags.tsx';
import renderTasks from './renderTasks.tsx';
import renderUsers from './renderUsers.tsx';

const renderCols: RenderColsT = function (data) {
    const { name } = this.props;

    if (name === 'executors') {
        return renderExecutors.call(this, data as ColsPropsT<'executors'>);
    }

    if (name === 'modelLogs') {
        return renderLogs.call(this, data as ColsPropsT<'modelLogs'>);
    }

    if (name === 'joins') {
        return renderJoins.call(this, data as ColsPropsT<'joins'>);
    }

    if (name === 'corporationBalances') {
        return renderCorporationBalances.call(this, data as ColsPropsT<'corporationBalances'>);
    }

    if (name === 'users') {
        return renderUsers.call(this, data as ColsPropsT<'users'>);
    }

    if (name === 'corporationReports') {
        return renderCorporationReports.call(this, data as ColsPropsT<'corporationReports'>);
    }

    if (name === 'corporations') {
        return renderCorporations.call(this, data as ColsPropsT<'corporations'>);
    }

    if (name === 'pays') {
        return renderPays.call(this, data as ColsPropsT<'pays'>);
    }

    if (name === 'signPays') {
        return renderPays.call(this, data as ColsPropsT<'pays'>);
    }

    if (name === 'paysGroups') {
        return renderPaysGroups.call(this, data as ColsPropsT<'paysGroups'>);
    }

    if (name === 'joinsContracts') {
        return renderJoinsContracts.call(this, data as ColsPropsT<'joinsContracts'>);
    }

    if (name === 'signJoinsContracts') {
        return renderJoinsContracts.call(this, data as ColsPropsT<'joinsContracts'>);
    }

    if (name === 'joinsScripts') {
        return renderJoinsScripts.call(this, data as ColsPropsT<'joinsScripts'>);
    }

    if (name === 'joinsTemplates') {
        return renderJoinsTemplates.call(this, data as ColsPropsT<'joinsTemplates'>);
    }

    if (name === 'joinsDocs') {
        return renderJoinsDocs.call(this, data as ColsPropsT<'joinsDocs'>);
    }

    if (name === 'executorsMvds') {
        return renderExecutorsMvds.call(this, data as ColsPropsT<'executorsMvds'>);
    }

    if (name === 'executorsTaxes') {
        return renderExecutorsTaxes.call(this, data as ColsPropsT<'executorsTaxes'>);
    }

    if (name === 'executorsLimits') {
        return renderExecutorsLimits.call(this, data as ColsPropsT<'executorsLimits'>);
    }

    if (name === 'tags') {
        return renderTags.call(this, data as ColsPropsT<'tags'>);
    }

    if (name === 'executorsBalances') {
        return renderExecutorsBalances.call(this, data as ColsPropsT<'executorsBalances'>);
    }

    if (name === 'chatMailings') {
        return renderChatMailings.call(this, data as ColsPropsT<'chatMailings'>);
    }

    if (name === 'chatTemplates') {
        return renderChatTemplates.call(this, data as ColsPropsT<'chatTemplates'>);
    }

    if (name === 'popupChatTemplates') {
        return renderChatTemplates.call(this, data as ColsPropsT<'chatTemplates'>);
    }

    if (name === 'payProjects') {
        return renderPayProjects.call(this, data as ColsPropsT<'payProjects'>);
    }

    if (name === 'listTagsGroups') {
        return renderListTagsGroups.call(this, data as ColsPropsT<'listTagsGroups'>);
    }

    if (name === 'listTagsGroupsColors') {
        return renderListColors.call(this, data as ColsPropsT<'listTagsGroupsColors'>);
    }

    if (name === 'listExecutors') {
        return renderListExecutors.call(this, data as ColsPropsT<'listExecutors'>);
    }

    if (name === 'listCorporations') {
        return renderListCorporations.call(this, data as ColsPropsT<'listCorporations'>);
    }

    if (name === 'listJoinScripts') {
        return renderListJoinScripts.call(this, data as ColsPropsT<'listJoinScripts'>);
    }

    if (name === 'listUsers') {
        return renderListUsers.call(this, data as ColsPropsT<'listUsers'>);
    }

    if (name === 'listJoinTemplates') {
        return renderListJoinTemplates.call(this, data as ColsPropsT<'listJoinTemplates'>);
    }

    if (name === 'listPayProjects') {
        return renderListPayProjects.call(this, data as ColsPropsT<'listPayProjects'>);
    }

    if (name === 'listRoles') {
        return renderListRoles.call(this, data as ColsPropsT<'listRoles'>);
    }

    if (name === 'listCountries') {
        return renderListCountries.call(this, data as ColsPropsT<'listCountries'>);
    }

    if (name === 'listPassportViews') {
        return renderListPassportViews.call(this, data as ColsPropsT<'listPassportViews'>);
    }

    if (name === 'staticList') {
        return renderStaticList.call(this, data as ColsPropsT<'staticList'>);
    }

    if (name === 'corporationRoles') {
        return renderCorporationRoles.call(this, data as ColsPropsT<'corporationRoles'>);
    }

    if (name === 'corporationRoleSections') {
        return renderCorporationRoleSections.call(
            this,
            data as ColsPropsT<'corporationRoleSections'>,
        );
    }

    if (name === 'corporationRoleSection') {
        return renderCorporationRoleSection.call(
            this,
            data as ColsPropsT<'corporationRoleSection'>,
        );
    }

    if (name === 'docs') {
        return renderDocs.call(this, data as ColsPropsT<'docs'>);
    }

    if (name === 'cities') {
        return renderCities.call(this, data as ColsPropsT<'cities'>);
    }

    if (name === 'countries') {
        return renderCountries.call(this, data as ColsPropsT<'countries'>);
    }

    if (name === 'listCountryTypes') {
        return renderListCountryTypes.call(this, data as ColsPropsT<'listCountryTypes'>);
    }

    if (name === 'executorTypes') {
        return renderExecutorTypes.call(this, data as ColsPropsT<'executorTypes'>);
    }

    if (name === 'listTags') {
        return renderListTags.call(this, data as ColsPropsT<'listTags'>);
    }

    if (name === 'listExecutorTypes') {
        return renderListExecutorTypes.call(this, data as ColsPropsT<'listExecutorTypes'>);
    }

    if (name === 'listExecutorOrganizations') {
        return renderListExecutorOrganizations.call(
            this,
            data as ColsPropsT<'listExecutorOrganizations'>,
        );
    }

    if (name === 'listCities') {
        return renderListCities.call(this, data as ColsPropsT<'listCities'>);
    }

    if (name === 'listDocTypes') {
        return renderListDocTypes.call(this, data as ColsPropsT<'listDocTypes'>);
    }

    if (name === 'listExecutorMvdStatuses') {
        return renderListExecutorMvdStatuses.call(
            this,
            data as ColsPropsT<'listExecutorMvdStatuses'>,
        );
    }

    if (name === 'exportItems') {
        return renderExportItems.call(this, data as ColsPropsT<'exportItems'>);
    }

    if (name === 'chatExecutorDocs') {
        return renderChatExecutorDocs.call(this, data as ColsPropsT<'chatExecutorDocs'>);
    }

    if (name === 'integrations') {
        return renderIntegrations.call(this, data as ColsPropsT<'integrations'>);
    }

    if (name === 'listIntegrationNames') {
        return renderListIntegrationNames.call(this, data as ColsPropsT<'listIntegrationNames'>);
    }

    if (name === 'listDealGroups') {
        return renderListDealGroups.call(this, data as ColsPropsT<'listDealGroups'>);
    }

    if (name === 'beelineUsers') {
        return renderBeelineUsers.call(this, data as ColsPropsT<'beelineUsers'>);
    }

    if (name === 'dealGroups') {
        return renderDealGroups.call(this, data as ColsPropsT<'dealGroups'>);
    }

    if (name === 'listDealColors') {
        return renderListColors.call(this, data as ColsPropsT<'listDealColors'>);
    }

    if (name === 'listDealTriggers') {
        return renderListDealTriggers.call(this, data as ColsPropsT<'listDealTriggers'>);
    }

    if (name === 'listTaskKeys') {
        return renderListTaskKeys.call(this, data as ColsPropsT<'listTaskKeys'>);
    }

    if (name === 'listTaskTimes') {
        return renderListTaskTimes.call(this, data as ColsPropsT<'listTaskTimes'>);
    }

    if (name === 'listPays') {
        return renderListPays.call(this, data as ColsPropsT<'listPays'>);
    }

    if (name === 'listDeals') {
        return renderListDeals.call(this, data as ColsPropsT<'listDeals'>);
    }

    if (name === 'listTaskModels') {
        return renderListTaskModels.call(this, data as ColsPropsT<'listTaskModels'>);
    }

    if (name === 'tasks') {
        return renderTasks.call(this, data as ColsPropsT<'tasks'>);
    }

    if (
        name === 'contentBlog' ||
        name === 'contentAnnounce' ||
        name === 'contentLegal' ||
        name === 'contentClientsKnowledge' ||
        name === 'contentUsersKnowledge'
    ) {
        return renderContentArticles.call(this, data as ColsPropsT<'contentBlog'>);
    }

    if (name === 'deals') {
        return renderDeals.call(this, data as ColsPropsT<'deals'>);
    }

    if (
        name === 'listArticleCrmBlogSections' ||
        name === 'listArticlePublicateTypes' ||
        name === 'listArticleSystems' ||
        name === 'listArticleCrmAnnounceSections' ||
        name === 'listDealSources' ||
        name === 'listDealRejectReasons' ||
        name === 'listDealGroupCustomFieldsTypes' ||
        name === 'listDealCustomFieldListItems' ||
        name === 'listArticleKnowledgeTypes' ||
        name === 'listArticleKnowledgeSections'
    ) {
        return renderDefaultList.call(this, data as ColsPropsT<'listArticleSystems'>);
    }

    if (name === 'listDealSteps') {
        return renderListDealSteps.call(this, data as ColsPropsT<'listDealSteps'>);
    }

    if (name === 'listArticles') {
        return renderListArticles.call(this, data as ColsPropsT<'listArticles'>);
    }

    return { content: null };
};

export default renderCols;
