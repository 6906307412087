import React from 'react';

import I from '../types.ts';

const renderFoot: I['renderFoot'] = function () {
    return (
        <div className={`chatWindow__foot ${0 ? '_disabled' : ''}`}>
            {this.renderFootFiles()}
            <div className="chatWindow__footInner _ROW">
                {this.renderFootArea()}
                {this.renderFootActions()}
            </div>
        </div>
    );
};

export default renderFoot;
