import React from 'react';

import Head from '../components/head/Head.tsx';

import I from '../types.ts';

const renderHead: I['renderHead'] = function () {
    const { chat, user } = this.state;
    const { mode } = this.props;

    if (process.env.REACT_APP_SYSTEM !== 'crm') {
        return <></>;
    }

    return (
        <div className="chatWindow__head">
            <Head chat={chat!} user={user!} addMessages={this.addMessages.bind(this)} mode={mode} />
        </div>
    );
};

export default renderHead;
