import React from 'react';

import checkImage from '@functions/checkImage.ts';

import I from '../types.ts';

const renderFile: I['renderFile'] = function () {
    const { message } = this.props;
    const [file] = message.files;

    if (!file) {
        return <></>;
    }

    if (checkImage(file.fullSrc!)) {
        return this.renderFileImage();
    }

    return this.renderFileDoc();
};

export default renderFile;
