import I from '../types.ts';

const getNodeId: I['getNodeId'] = function () {
    const { chat } = this.state;
    const { mode = 'all' } = this.props;

    if (!chat) {
        return '';
    }

    return `${mode}${chat._id}`;
};

export default getNodeId;
