import React from 'react';

import Link from '@components/link/Link.tsx';
import getFormatedDate from '@functions/getFormatedDate.ts';
import setSpacesInText from '@functions/setSpacesInText.ts';
import shortText from '@functions/shortText';
import TaskT from '@global/models/Task.ts';
import { CursorActionsItemT } from '@global/types.ts';
import { PageNamesT } from '@redux/pages.ts';

import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

const renderTasks: RenderColsT<'tasks'> = function ({ name: colName, item }) {
    let content;
    let className;
    const task = item as TaskT;

    if (colName === 'number') {
        content = <>{task.number}</>;
    }

    if (colName === 'responsible') {
        content = <>{task.responsibleName}</>;
    }

    if (colName === 'creater') {
        content = (
            <>
                <div className="v2table__text">{task.createrName}, </div>
                <div className="v2table__text">
                    до&nbsp;
                    {getFormatedDate({
                        date: new Date(task.dateTime!),
                        isShortYear: true,
                        type: 'full',
                    })}
                </div>
            </>
        );
    }

    if (colName === 'key') {
        content = (
            <>
                <div className="v2table__text">{task.keyText}</div>
            </>
        );
    }

    if (colName === 'target') {
        let pageName: PageNamesT['crm'] | undefined;
        let ids;

        if (task.targetName === 'deal') {
            pageName = 'recruit-deals-group-inner';
            ids = {
                4: task.targetId,
            };
        }

        if (task.targetName === 'pay') {
            pageName = 'pays-main-inner';
            ids = {
                3: task.targetId,
            };
        }

        content = (
            <>
                <Link
                    className="v2table__text _link"
                    pageName={pageName}
                    ids={ids}
                    isStopPropagation={true}
                >
                    {task.targetFullName}
                </Link>
            </>
        );
    }

    if (colName === 'description') {
        content = (
            <>
                <div
                    className="v2table__text"
                    dangerouslySetInnerHTML={{
                        __html: setSpacesInText(
                            shortText({ str: task.description, stop: 32, type: 'word' }),
                        ),
                    }}
                ></div>
            </>
        );
    }

    if (colName === 'status') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as (data: {
            model: TaskT;
        }) => CursorActionsItemT[];
        const actions = getMoreActions({ model: task });

        const statuses: Record<string, string> = {
            new: '_new',
            process: '',
            check: '_process',
            completed: '_active',
            alert: '_alert',
        };
        const statusClass = statuses[task.status];

        content = (
            <>
                <div className={`v2table__status _block ${statusClass}`}>{task.statusText}</div>
                <More id={`task-${task._id}`} items={actions} stopPropagation={true} />
            </>
        );
    }

    return { content, className };
};

export default renderTasks;
