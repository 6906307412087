import { throttle } from 'throttle-debounce';

import removeTransition from '@functions/removeTransition.ts';
import setAsyncState from '@functions/setAsyncState.ts';
import { ChatT } from '@global/models/Chat.ts';
import getListItems from '@requests/getListItems.ts';

import I from '../types.ts';

const getChat: I['getChat'] = async function () {
    const { id, ready } = this.props;

    try {
        const { chat } = await getListItems<{}, { chat: ChatT }>({
            url: 'chat',
            query: [{ key: 'id', value: id }],
        });

        await setAsyncState.call(this, { chat });

        removeTransition({ item: `.chatWindow__content._${this.getNodeId()}` });

        this.setEvents();

        await this.startGetItems();

        if (ready) {
            ready();
        }

        this.readThrottle = throttle(1_000, this.readMessages.bind(this));
    } catch (error) {}
};

export default getChat;
