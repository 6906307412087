import setSpacesInText from '@functions/setSpacesInText.ts';

import I from '../types.ts';

const setMessageContent: I['setMessageContent'] = function ({ message }) {
    if (!message.content) {
        return;
    }

    const { chat } = this.state;
    const clearedContent = message.content.replace(/\n/g, ' \n ').replace(/\u00A0/g, ' ');
    const resultContent: string[] = [];

    clearedContent.split(' ').forEach((item) => {
        if (item.includes('http') || item.includes('://')) {
            resultContent.push(`<a href="${item}" target="_blank" class="_wrap">${item}</a>`);
        } else {
            resultContent.push(item);
        }
    });

    let resultText = resultContent.join(' ').replace(/\n/g, '<br/>');

    if (process.env.REACT_APP_SYSTEM === 'crm' && message.isMe && chat!.anyInfo?.userId) {
        const match = Array.from(resultText.matchAll(/(?:\d)[\d ]{14,}(?:\d)/gm));
        let numbersText = '';

        if (match.length === 0) {
            numbersText = resultText;
        }
        match.forEach((item, key) => {
            const startIndex = key === 0 ? 0 : match[key - 1].index + match[key - 1][0].length;

            numbersText += resultText.slice(startIndex, item.index);
            numbersText += `<span class="_cardNumber _click">${item[0]}</span>`;

            if (key === match.length - 1) {
                numbersText += resultText.slice(item.index + item[0].length);
            }
        });

        resultText = numbersText;
    }

    return setSpacesInText(resultText);
};

export default setMessageContent;
