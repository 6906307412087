import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import checkImage from '@functions/checkImage.ts';

import I from '../types.ts';

const renderOther: I['renderOther'] = function () {
    const { file, withOpen } = this.props;
    const sizeText = this.getSizeText();

    return (
        <>
            <div
                className={`chatFile__preview ${withOpen && file.fullSrc ? '_CLICK _link' : ''}`}
                onClick={(e) => {
                    if (withOpen && file.fullSrc) {
                        e.stopPropagation();

                        window.open(file.fullSrc, '_blank');
                    }
                }}
            >
                {file.fullSrc && checkImage(file.fullSrc) ? (
                    <img src={file.fullSrc} alt="" className="chatFile__image" />
                ) : (
                    <i className="chatFile__logo">
                        <Icon name="file-type-image" />
                    </i>
                )}
                <i className="chatFile__icon">
                    <Icon name={`file-type-${file.type}` as IconsT} />
                </i>
            </div>
            <div className="chatFile__info _COL">
                <p className="chatFile__name">{file.name}</p>
                <p className="chatFile__size">{sizeText}</p>
            </div>
        </>
    );
};

export default renderOther;
