import React from 'react';

import Icon from '@components/icon/Icon.tsx';

import I from '../types.ts';

const renderReadState: I['renderReadState'] = function () {
    const { message } = this.props;
    const { isMe, type, isRead } = message;

    if (!isMe || type) {
        return;
    }

    return (
        <div className="chatMessage__read _row">
            <i className="chatMessage__readIcon">
                <Icon name="chat-done" />
            </i>
            <i className={`chatMessage__readIcon ${!isRead ? '_hide' : ''}`}>
                <Icon name="chat-done-read" />
            </i>
        </div>
    );
};

export default renderReadState;
