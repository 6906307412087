import React from 'react';

import I from '../types.ts';

const renderItem: I['renderItem'] = function ({ item }) {
    const id = item._id;
    const { items, itemClass = '' } = this.props;

    const isFirst = this.indexes[id] === 0;
    const isLast = this.indexes[id] === items.length - 1;
    const isShow = this.states[id] === 1;
    const isHide = this.states[id] === 0;

    return (
        <div
            className={`list__item ${itemClass} ${isFirst ? '_first' : ''} ${isLast ? '_last' : ''} ${isShow ? '_show' : ''}`}
            data-id={id}
            key={id}
        >
            <div className="list__itemBox">
                {this.props.renderItem({
                    item,
                    isHide,
                    isShow,
                    isFirst,
                    isLast,
                })}
            </div>
        </div>
    );
};

export default renderItem;
