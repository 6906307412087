import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const addMessages: I['addMessages'] = async function ({ messages, ...stateProps }) {
    const resultMessages = this.setMessages({ messages }).reverse();

    const stateMessages = [...this.state.items!.map((item) => structuredClone(item))];

    resultMessages.forEach((newMessage) => {
        if (!stateMessages.find((item) => item._id === newMessage._id)) {
            stateMessages.unshift({ ...newMessage });
        }
    });

    await setAsyncState.call(this, {
        items: stateMessages,
        updatedAreaKey: new Date().getTime(),
        ...this.getMessagesKeys({ messages: resultMessages }),
        ...stateProps,
    });
};

export default addMessages;
