import React from 'react';

import Icon from '@components/icon/Icon.tsx';

import I from '../types.ts';

const renderFootTemplatesAction: I['renderFootTemplatesAction'] = function () {
    if (process.env.REACT_APP_SYSTEM !== 'crm') {
        return <></>;
    }

    return (
        <div
            className={`chatWindow__footAction _COL _templates ${0 ? '_disabled' : ''}`}
            onClick={() => {
                this.showTemplatesPopup();
            }}
        >
            <div className="chatWindow__footActionInner _col _click">
                <i className="chatWindow__footActionIcon">
                    <Icon name="chat-templates" />
                </i>
            </div>
        </div>
    );
};

export default renderFootTemplatesAction;
