const contentUsersKnowledge = {
    colsOrder: ['preview', 'title', 'section'],
    cols: {
        preview: {
            content: 'Превью',
            width: 17,
        },
        title: {
            content: 'Название',
            width: 63,
        },
        section: {
            content: 'Раздел',
            width: 20,
        },
    },
    deletesCols: [],
    url: 'article',
    emptyInfo: {
        title: 'Статей пока нет',
        description: 'Вы можете создать статью по кнопке справа',
    },
    query: [
        { key: 'type', value: 'usersKnowledge' },
        { key: 'system', value: 'crm' },
        { key: 'private', value: 'true' },
    ],
} as const;

export default contentUsersKnowledge;
