import I from '../types.ts';

const sendVoice: I['sendVoice'] = function () {
    if (this.state.isVoiceProccess) {
        const voiceFile = new File(this.voice, 'audio.ogg', {
            type: 'audio/ogg',
        });

        if (voiceFile.size > 15_000 && !this.isCancelVoice) {
            const formData = new FormData();

            formData.set('voice', voiceFile);

            this.sendMessage({ isVoice: true, formData });
        } else {
            this.voice = [];
            this.isCancelVoice = false;
        }

        this.setState({ isVoiceProccess: false });
    }
};

export default sendVoice;
