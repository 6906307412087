import I from '../types.ts';

const visibilityChange: I['visibilityChange'] = function () {
    if (!document.hidden) {
        if (this.state.isVoiceProccess) {
            this.endVoice(true);
        }

        this.pressShift = false;
    }
};

export default visibilityChange;
