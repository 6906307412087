import lists from '../lists';

const listDealRejectReasons = {
    ...lists,
    query: [{ key: 'type', value: 'dealRejectReasons' }],
    emptyInfo: {
        title: 'Причин пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default listDealRejectReasons;
