import I from '../types.ts';

const getMessagesKeys: I['getMessagesKeys'] = function ({ messages }) {
    const messagesRenderKey = messages.map((message) => message._id).join('');
    const messagesUpdateKey = messages
        .map((message) => `${message.notRead}${message.content}`)
        .join('');

    return {
        messagesRenderKey,
        messagesUpdateKey,
    };
};

export default getMessagesKeys;
