import download from '@functions/download.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { CursorActionsItemT } from '@global/types.ts';

import I from '../types.ts';

const getImageMoreActions: I['getImageMoreActions'] = function ({ id }) {
    const { user, chat } = this.props;
    const actions: CursorActionsItemT[] = [
        {
            name: 'download',
            key: 'download',
            onClick: async () => {
                const { message } = this.props;
                const [file] = message.files;
                const name = file.name.split('.');

                name.pop();

                await download({ name: name.join('_'), files: [{ src: `chats/${file.path}` }] });
            },
        },
    ];

    if (
        chat.responsible === user._id &&
        (chat.type === 'telegram' ? chat.anyInfo.userId : chat.entity.type === 'executor')
    ) {
        actions.push({
            name: 'addDocForExecutor',
            key: 'add',
            onClick: async () => {
                handlerPopup('chatExecutorDocPopup', {
                    isShow: true,
                    id: chat._id,
                    fileId: id,
                });
            },
            text: 'Прикрепить к исполнителю',
        });
    }

    return actions;
};

export default getImageMoreActions;
