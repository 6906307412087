export default function setAsyncTimer(
    duration = 1_000,
    setId?: (id: ReturnType<typeof setTimeout>) => void,
): Promise<void> {
    let timerId: ReturnType<typeof setTimeout> | undefined;

    return new Promise((resolve) => {
        timerId = setTimeout(() => {
            resolve();
        }, duration);

        if (setId) {
            setId(timerId);
        }
    });
}
